import { FunctionComponent, useContext } from 'react'
import { config } from '@constants'
import { ProjectAnalyticsRoute, ProjectDataSourcesRoute, ProjectRoute, RouterContext } from '@lib/app/routing'
import { FEATURES } from '@features'
import { IconName, SidebarNavigationLink } from '@components/base'
import classes from '@components/base/Sidebar/SidebarNavigation.module.scss'
import { AppSettingsContext } from '@lib/app/settings'
import { ProjectContext } from '@lib/app/project'

interface IProjectSidebarNavigationProps {
    projectSlug: string
}

function buildProjectRoute(
    slug: string,
    route?: ProjectRoute,
    subRoute?: ProjectAnalyticsRoute | ProjectDataSourcesRoute
): string {
    return route ? `/projects/${slug}/${route}${subRoute ? '/' + subRoute : ''}` : `/projects/${slug}`
}

export const ProjectSidebarNavigation: FunctionComponent<IProjectSidebarNavigationProps> = (props) => {
    const { projectSlug } = props
    const isDark = useContext(AppSettingsContext).data.theme === 'dark'
    const pathname = useContext(RouterContext)?.data.location.pathname

    const optionalPages = [FEATURES.project.guardian]
    const isOneOptionalPageEnabled = optionalPages.some((page) => page.enabled)

    const hasAnalyticsProfile = Boolean(useContext(ProjectContext)?.data?.analyticsConfiguration)

    return (
        <nav className={`${classes['navigation']} ${isDark && classes['navigation-dark']}`}>
            {FEATURES.project.dashboard.enabled && (
                <SidebarNavigationLink
                    url={buildProjectRoute(projectSlug, ProjectRoute.Dashboard)}
                    icon={IconName.Dashboard}
                    text='Dashboard'
                />
            )}
            {FEATURES.project.analytics.enabled && hasAnalyticsProfile && (
                <SidebarNavigationLink
                    url={buildProjectRoute(projectSlug, ProjectRoute.Analytics)}
                    icon={IconName.Analytics}
                    text='Analytics'
                    isActive={pathname?.includes('analytics')}
                />
            )}
            {FEATURES.project.dataSources.enabled && (
                <SidebarNavigationLink
                    url={buildProjectRoute(projectSlug, ProjectRoute.DataSources)}
                    icon={IconName.Cpu}
                    text='Data Sources'
                    isActive={pathname?.includes('data-sources')}
                    svgProperties={['stroke']}
                />
            )}
            {FEATURES.project.documentation.enabled && (
                <SidebarNavigationLink
                    url={buildProjectRoute(projectSlug, ProjectRoute.Documentation)}
                    icon={IconName.Document}
                    text='Documentation'
                    isActive={pathname?.includes('documentation')}
                    svgProperties={['stroke']}
                />
            )}
            {FEATURES.project.activity.enabled && (
                <SidebarNavigationLink
                    url={buildProjectRoute(projectSlug, ProjectRoute.Activity)}
                    icon={IconName.Activity}
                    text='Activity'
                    isActive={pathname?.includes('activity')}
                    svgProperties={['stroke']}
                />
            )}
            {config.DEMO_MODE ? (
                <></>
            ) : (
                <>
                    {FEATURES.project.users.enabled && (
                        <SidebarNavigationLink
                            url={buildProjectRoute(projectSlug, ProjectRoute.Users)}
                            icon={IconName.Users}
                            text='Users'
                            isActive={pathname?.includes('users')}
                            svgProperties={['stroke']}
                        />
                    )}
                    {FEATURES.project.dataSources.addSource.enabled && (
                        <SidebarNavigationLink
                            url={buildProjectRoute(
                                projectSlug,
                                ProjectRoute.DataSources,
                                ProjectDataSourcesRoute.AddSensor
                            )}
                            icon={IconName.Cpu}
                            text='Add Sensor'
                            isActive={pathname?.includes('add-sensor')}
                            svgProperties={['stroke']}
                        />
                    )}
                </>
            )}
            {isOneOptionalPageEnabled && (
                <>
                    <div className='border-b-[1px] border-[var(--stroke)] dark:border-[var(--stroke-dark)]'></div>
                    {FEATURES.project.guardian.enabled && (
                        <SidebarNavigationLink
                            url={buildProjectRoute(projectSlug, ProjectRoute.Guardian)}
                            icon={IconName.Guardian}
                            text='Guardian'
                            isActive={pathname?.includes('guardian')}
                            svgProperties={['stroke']}
                        />
                    )}
                </>
            )}
        </nav>
    )
}
