import { FunctionComponent, useContext } from 'react'
import classes from './CheckboxInput.module.scss'
import { AppSettingsContext } from '@lib/app/settings'

interface ICheckboxInputProps {
    id: string
    label: string
    checked?: boolean
    disabled?: boolean
    onClick?: () => void
}

/**
 * Displays a checkbox-based input component to the page.
 *
 * @param props
 */
export const CheckboxInput: FunctionComponent<ICheckboxInputProps> = (props) => {
    const { id, label, checked, disabled, onClick = () => {} } = props
    const isDark = useContext(AppSettingsContext).data.theme === 'dark'
    return (
        <div className='flex flex-row items-center space-x-4'>
            <input
                type='checkbox'
                id={id}
                defaultChecked={checked ?? false}
                disabled={disabled ?? false}
                className={`${classes.input} ${isDark ? classes.inputDark : ''}`}
                onClick={onClick}
            />
            <label htmlFor={id} className={`${classes.label} ${isDark ? classes.labelDark : ''}`}>
                {label}
            </label>
        </div>
    )
}
