import { FunctionComponent, useEffect, useRef, useState } from 'react'
import { useCookies } from 'react-cookie'
import { useNavigate } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { IMenuAnchorProps, IMenuItemProps, Menu } from '@components/base/Menu'
import { config } from '@constants'
import { createApiService } from '@lib/core/api'
import { ProfileAvatar } from './ProfileAvatar.tsx'

export const ProfileMenu: FunctionComponent = () => {
    const api = createApiService(config.API_BASE_URL)
    const navigate = useNavigate()
    const { isAuthenticated, logout, user } = useAuth0()
    const [, setCookie] = useCookies(['username'])
    const [initials, setInitials] = useState<string>('')

    function onProfileClick(): void {
        navigate('/profile')
    }

    async function onSignOutClick(): Promise<void> {
        api.cache.clear()
        await api.auth.logout().catch((error) => {
            console.error('Error signing out:', error)
        })

        setCookie('username', '')
        await logout({ logoutParams: { returnTo: window.location.origin } })
    }

    const profileMenuItems: IMenuItemProps[] = [
        {
            title: 'Profile',
            onClick: onProfileClick,
        },
        {
            title: 'Sign Out',
            onClick: () => void onSignOutClick(),
            variant: 'danger',
        },
    ]

    useEffect(() => {
        if (isAuthenticated && user && user.name) {
            const firstLetter = user?.name.toUpperCase()[0]
            const secondLetter = user?.family_name ? user?.family_name[0] : user?.name.toLowerCase()[1]
            setInitials([firstLetter, secondLetter].join(''))
        }
    })

    const AnchorComponent: FunctionComponent<IMenuAnchorProps> = (props) => {
        const { onMenuClick, anchorRef } = props
        const ref = useRef<HTMLDivElement>(anchorRef?.current as HTMLDivElement)
        return (
            <div ref={ref}>
                <ProfileAvatar initials={initials} onClick={onMenuClick} />
            </div>
        )
    }
    return <Menu items={profileMenuItems} AnchorComponent={AnchorComponent} />
}
