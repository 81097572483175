import { FunctionComponent, ReactNode, RefObject, useEffect, useRef } from 'react'

interface IOutsideAlerter {
    children: ReactNode
    onClick: () => void
}

function useOutsideAlerter(ref: RefObject<HTMLDivElement>, onClick: () => void): void {
    useEffect(() => {
        function onOutsideClick(event: MouseEvent) {
            if (ref.current && !ref.current.contains(event.target as Node)) {
                onClick()
            }
        }

        document.addEventListener('mousedown', onOutsideClick)

        return () => {
            document.removeEventListener('mousedown', onOutsideClick)
        }
    }, [ref])
}

export const OutsideAlerter: FunctionComponent<IOutsideAlerter> = (props) => {
    const { children, onClick } = props
    const wrapperRef = useRef<HTMLDivElement>(null)
    useOutsideAlerter(wrapperRef, onClick)

    return (
        <div ref={wrapperRef} className='drop-shadow-lg z-10'>
            {children}
        </div>
    )
}
