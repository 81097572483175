import { FunctionComponent } from 'react'
import { Text } from '@components/base/Text'
import { TableRow } from './TableRow.tsx'
import { ITableRowProps } from './types.ts'

export const TextTableRow: FunctionComponent<ITableRowProps<string[]>> = (props) => {
    const entries = props?.data ?? []
    return (
        <TableRow>
            {entries.map((entry, index) => (
                <Text key={index} type='body1'>
                    {entry}
                </Text>
            ))}
        </TableRow>
    )
}
