import { FunctionComponent } from 'react'
import { IProjectDocument } from '@lib/app/project'
import { TableRow, ITableRowProps } from '@components/base/Table'
import { Text } from '@components/base/Text'
import { sizeToReadable, dateToReadable } from '@lib/utils'

interface IProjectDocumentationTableRowProps {
    hideEntity?: boolean
}

export const ProjectDocumentationTableRow: FunctionComponent<
    IProjectDocumentationTableRowProps & ITableRowProps<IProjectDocument>
> = (props) => {
    const { hideEntity, data } = props ?? {}
    const { name, owner, metadata, lastModified } = data?.file ?? {}
    const entries = [
        name,
        ...(hideEntity ? [] : [owner]),
        metadata?.type ?? '',
        metadata?.size ? sizeToReadable(metadata.size) : '0',
        lastModified ? dateToReadable(lastModified) : '-',
    ]

    return (
        <TableRow>
            {entries.map((entry) => (
                <Text type='body1'>{entry?.toString()}</Text>
            ))}
        </TableRow>
    )
}
