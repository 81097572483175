import { FunctionComponent, ReactNode, useMemo, useState } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { config } from '@constants'
import { createApiService } from '@lib/core/api'
import { IContext } from '@lib/core/context'
import { IUser, IUserContextData, UserContext } from '@lib/app/user'
import { MS_PER_SECOND } from '@lib/utils'

const OVERVIEW_POLLING_INTERVAL_MS = 30 * MS_PER_SECOND
const LOADING_POLLING_INTERVAL_MS = 6 * MS_PER_SECOND
let loaded = false;

interface IUserProviderProps {
    children: ReactNode
}

export const UserProvider: FunctionComponent<IUserProviderProps> = (props) => {
    const { children } = props
    const { user = {} } = useAuth0() ?? {}

    const api = createApiService(config.API_BASE_URL)

    const [userData, setUserData] = useState<IUserContextData>({ user: user as IUser, sites: [] })
    const userContext: IContext<IUserContextData> = {
        data: userData,
        setData: onUserDataUpdate,
    }

    function onUserDataUpdate(payload: Partial<IUserContextData>): void {
        setUserData({
            ...userData,
            ...payload,
        })
    }

    let pollingInterval: number = 0

    async function updateOverview(): Promise<void> {
        try {
            const overview = await api.context.getProjects(false)
            console.debug("Got overview: ", overview)
            if ('sites' in overview) {
                if (userContext.data.sites !== overview.sites) {
                    userContext.data.sites = overview.sites
                    setUserData({
                        ...userData,
                        sites: overview.sites,
                    })
                    if (!loaded && overview.sites.length > 0) {
                        loaded = true
                    }
                }
            } else {
                // Do nothing ("redirected")
            }
        } catch (err) {
            console.error(err)
        }
    }

    useMemo(() => {
        if (!loaded) {
            void  updateOverview()
            if (!loaded) {
                pollingInterval = setInterval(() => {
                    if (loaded) {
                        console.log("Clearing interval")
                        clearInterval(pollingInterval)
                    }
                    void updateOverview()
                }, LOADING_POLLING_INTERVAL_MS) as unknown as number;
            }
        }

        if (!pollingInterval && loaded) {
            pollingInterval = setInterval(() => {
                void updateOverview()
            }, OVERVIEW_POLLING_INTERVAL_MS) as unknown as number
        }

        return () => {
            pollingInterval && clearInterval(pollingInterval)
        }
    }, [loaded])

    return <UserContext.Provider value={userContext}>{children}</UserContext.Provider>
}
