import { ChangeEvent, ChangeEventHandler, FunctionComponent, useContext, useRef, useState } from 'react'
import _ from 'lodash'
import { IconButton } from '../Button'
import { IconName } from '@components/base/Icon'
import classes from './SearchInput.module.scss'
import { AppSettingsContext } from '@lib/app/settings'

export const SearchInput: FunctionComponent<{ onChange: ChangeEventHandler<HTMLInputElement> }> = ({ onChange }) => {
    const isDark = useContext(AppSettingsContext).data.theme === 'dark'
    const [isActive, setIsActive] = useState<boolean>(false)
    const inputRef = useRef<HTMLInputElement>(null)

    const setActive = () => {
        setIsActive(true)
        inputRef.current?.focus()
    }

    const setNotActive = () => {
        setIsActive(false)
        inputRef?.current?.blur()
    }

    const toggleActive = () => {
        !isActive ? setActive() : setNotActive()
    }

    const thisOnChange = _.debounce((event: ChangeEvent<HTMLInputElement>) => {
        onChange(event)
    }, 500)

    return (
        <div
            className={`${classes.searchInputContainer} ${isDark && classes.searchInputContainerDark} ${isActive ? classes.active : ''}`}
            onMouseLeave={setNotActive}
        >
            <IconButton icon={IconName.Search} onClick={toggleActive} disabled={isActive} />
            <input
                ref={inputRef}
                placeholder='Search'
                type='text'
                onChange={thisOnChange}
                onFocus={setActive}
                onBlur={setNotActive}
            />
        </div>
    )
}
