import { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { config } from '@constants'
import { createApiService } from '@lib/core/api'
import classes from './ProjectDashboardPage.module.scss'
import {
    MolinaSensorMap3D,
    ProjectInfo,
    ProjectLayout,
    ProjectRecentActivity,
    ProjectStatistic,
    SitePlan,
} from '@components/app'
import { Skeleton } from '@components/base'
import { IProjectDetails, IProjectNotification, IProjectStatistic, ProjectContext } from '@lib/app/project'
import { AppSettingsContext } from '@lib/app/settings'

const PROJECT_STATISTICS: IProjectStatistic[] = [
    {
        type: 'sustainability',
        amount: '',
        unit: '',
        text: 'Annual GHG emission reductions',
    },
    {
        type: 'sustainability',
        amount: '',
        unit: '',
        text: 'GHG emission reductions last 30 day',
    },
    {
        type: 'chart',
        amount: '',
        unit: '%',
        text: 'Data Confidence',
    },
]

export const ProjectDashboardPage = () => {
    const isDark = useContext(AppSettingsContext).data.theme === 'dark'
    const { details } = useContext(ProjectContext).data
    const [project, setProject] = useState<IProjectDetails | null>(null)
    const [activity, setActivity] = useState<IProjectNotification[]>([])
    const [statistics, setStatistics] = useState<IProjectStatistic[]>([
        { ...PROJECT_STATISTICS[0] },
        { ...PROJECT_STATISTICS[1] },
        { ...PROJECT_STATISTICS[2] },
    ])

    const navigate = useNavigate()
    const api = createApiService(config.API_BASE_URL)

    console.log('ProjectDashboardPage ', details);
    useEffect(() => {
        if (details) {
            setProject(details)
        }

        api.context.getNotifications().then((data) => {
            data.notifications.sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime())
            setActivity(data.notifications)
        })
    }, [details])

    const parseValue = (value: string) => {
        const parsed = parseFloat(value)
        return parseFloat(parsed.toFixed(1)).toLocaleString()
    }

    useEffect(() => {
        if (project) {
            const ghgAnnual = {
                type: 'sustainability',
                amount: parseValue(project.ghgAnnual.value),
                unit: project.ghgAnnual.unit,
                text: project.ghgAnnual.label,
            }

            const ghg30Day = {
                type: 'sustainability',
                amount: parseValue(project.ghgLast30Days.value),
                unit: project.ghgLast30Days.unit,
                text: project.ghgLast30Days.label,
            }

            const dcf = {
                type: 'chart',
                amount: project.avgDcf ? project.avgDcf : '0',
                unit: '%',
                text: 'Data Confidence',
            }

            setStatistics([ghgAnnual, ghg30Day, dcf])
        }
    }, [project])

    const mapDataToProjectInfo = () => {
        if (project && project.projectInfo && project.projectInfo) {
            const { projectId, type, methodology, projectDev } = project.projectInfo
            return {
                projectInfo: {
                    projectId,
                    type,
                    methodology,
                    projectDev,
                },
                location: project.location,
            }
        } else {
            return {
                projectInfo: {
                    projectId: '',
                    type: '',
                    methodology: '',
                    projectDev: '',
                },
                location: {
                    address: '',
                    lat: 0.0,
                    lon: 0.0,
                },
            }
        }
    }

    const location = useParams()

    // TODO: This logic shouldn't be here; move it somewhere else
    useEffect(() => {
        const getStatus = async () => {
            await api.auth.status()
        }

        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })

        getStatus().catch(() => {
            console.error('Error authenticating, redirecting to login')
            navigate('')
        })
    }, [location, history])

    return (
        <ProjectLayout title={project ? project.projectName : 'Dashboard'}>
            <div className='flex flex-col space-y-8'>
                <div className={classes.project}>
                    <div>
                        <div className={classes.content}>
                            <div className={`${classes.innerContent} ${isDark && classes.innerContentDark}`}>
                                <div className={classes.topContent}>
                                    <ProjectInfo {...mapDataToProjectInfo()} />
                                    {activity ? (
                                        <ProjectRecentActivity loading={!details?.notifications} activity={activity} />
                                    ) : (
                                        <Skeleton />
                                    )}
                                </div>
                                <div className={classes.statisticWrapper}>
                                    {statistics.map((i: any, index: any) => (
                                        <ProjectStatistic key={index} statistic={i} />
                                    ))}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {project ? (
                    project.projectInfo.projectId === 'BEM' ? (
                        <MolinaSensorMap3D />
                    ) : (
                        <SitePlan />
                    )
                ) : (
                    <Skeleton height={512} />
                )}
            </div>
        </ProjectLayout>
    )
}
