import { FunctionComponent, useState } from 'react'
import { ProjectLayout, ProjectUsersTable } from '@components/app'
import { Button, IconName, Text } from '@components/base'
import { IUser, UserRole } from '@lib/app/user'

const MOCK_USERS: IUser[] = [
    {
        name: 'Max Mustermann',
        role: UserRole.Admin,
        lastLogin: new Date(Math.random() * 1000 + 1726257953365),
        permissions: ['Santa Maria 2', 'Santa Maria 2', 'Santa Maria 2', 'Santa Maria 2'],
    },
    {
        name: 'Lisa Mustermann',
        role: UserRole.Viewer,
        lastLogin: new Date(Math.random() * 1000 + 1726257953365),
        permissions: ['Santa Maria 2'],
    },
    {
        name: 'Mathew Yarger',
        role: UserRole.Admin,
        lastLogin: new Date(Math.random() * 1000 + 1726257953365),
        permissions: ['Site 49', 'Santa Maria 2', 'Santa Maria 3'],
    },
    {
        name: 'Dyrell Chapman',
        role: UserRole.Admin,
        lastLogin: new Date(Math.random() * 1000 + 1726257953365),
        permissions: ['Site 49', 'Santa Maria 2'],
    },
    {
        name: 'The Destroyer',
        role: UserRole.Viewer,
        lastLogin: new Date(Math.random() * 1000 + 1726257953365),
        permissions: ['Santa Maria 2'],
    },
    {
        name: 'Matthew Maxwell',
        role: UserRole.Viewer,
        lastLogin: new Date(Math.random() * 1000 + 1726257953365),
        permissions: ['Santa Maria 2', 'Santa Maria'],
    },
]

export const ProjectUsersPage: FunctionComponent = () => {
    const [loading, setLoading] = useState(true)

    setTimeout(() => {
        setLoading(false)
    }, 3000)

    const TitleComponent = () => {
        return (
            <>
                <Text type='header2'>Users</Text>
                <Button size='medium' icon={IconName.Plus} onClick={() => {}}>
                    Add New
                </Button>
            </>
        )
    }

    return (
        <ProjectLayout TitleComponent={TitleComponent}>
            <ProjectUsersTable loading={loading} users={MOCK_USERS} />
        </ProjectLayout>
    )
}
