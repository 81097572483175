import { useCallback, useEffect, useRef } from 'react'
import { GoogleMap, MarkerF, useJsApiLoader } from '@react-google-maps/api'
import { ISiteLocation } from '@lib/app/project'
import classes from './ProjectDetails.module.scss'

export const GoogleMaps: React.FC<ISiteLocation> = (location) => {
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: import.meta.env.VITE_MAPS_API_KEY || '',
    })

    const mapRef = useRef<google.maps.Map | null>(null)

    const center = {
        lat: location.lat,
        lng: location.lon,
    }

    const mapOptions = {
        disableDefaultUI: true,
        zoomControl: true,
        draggable: false,
    }

    const onLoad = useCallback((new_map: google.maps.Map) => {
        const newMapTypeId = google.maps.MapTypeId.HYBRID
        new_map.setMapTypeId(newMapTypeId)
        mapRef.current = new_map
    }, [])

    const onUnmount = useCallback(() => {
        mapRef.current = null
    }, [])

    useEffect(() => {
        if (mapRef.current) {
            mapRef.current.setCenter({
                lat: location.lat,
                lng: location.lon,
            })
        }
    }, [location])

    return (
        <div className={classes.map}>
            {isLoaded ? (
                <GoogleMap
                    mapContainerStyle={{ height: '280px', width: '100%' }}
                    center={center}
                    zoom={16}
                    onLoad={onLoad}
                    onUnmount={onUnmount}
                    options={mapOptions}
                >
                    <MarkerF position={center} />
                </GoogleMap>
            ) : (
                <div style={{ height: '20vh', width: '100%' }} />
            )}
        </div>
    )
}
