import {FunctionComponent, useState} from 'react'
import { createBrowserRouter, RouterProvider as _RouterProvider } from 'react-router-dom'
import products from '@assets/json/projects.json'
import { IProject } from '@lib/app/project'
import {
    AppRoute,
    buildProjectRoute,
    buildRoute,
    IRouterContextData,
    ProjectAnalyticsRoute,
    ProjectDataSourcesRoute,
    ProjectGuardianRoute,
    ProjectRoute,
    RouterContext,
} from '@lib/app/routing'
import { ProjectProvider, UserAuthProvider, UserProvider } from '@components/app'
import { ErrorBoundaryLayout } from '@components/base'
import { AddStreamPage } from './AddStream'
import { IdentityPage } from './Identity'
import { OverviewPage } from './Overview'
import { ProfilePage } from './Profile'
import {
    AddProjectDataSourcePage,
    RegisterProjectGuardianPage,
    ProjectActivityPage,
    ProjectAnalyticsDetailsPage,
    ProjectAnalyticsPage,
    ProjectAnalyticsParameterCustomizationPage,
    ProjectAnalyticsSetupPage,
    ProjectDashboardPage,
    ProjectDataSourcePage,
    ProjectDataSourcesPage,
    ProjectDocumentationPage,
    ProjectGuardianPage,
    ProjectUsersPage,
} from './Project'
import { RegisterPage, SignInPage } from './Onboarding'
import { IContext } from '@lib/core/context'

const ROUTER = createBrowserRouter([
    {
        element: <ErrorBoundaryLayout/>,
        children: [
            // ========================================================================================================
            // ONBOARDING
            {path: buildRoute(AppRoute.Register), element: <RegisterPage/>},
            {
                path: buildRoute(AppRoute.SignIn),
                element: (
                    <UserAuthProvider>
                        <SignInPage/>
                    </UserAuthProvider>
                ),
            },
            // ========================================================================================================
            // PROFILE
            {
                path: buildRoute(AppRoute.AddStream),
                element: (
                    <AddStreamPage/>
                ),
            },
            {
                path: buildRoute(AppRoute.Identity),
                element: (
                    <IdentityPage/>
                ),
            },
            {
                path: AppRoute.Overview,
                element: (
                    <UserAuthProvider>
                        <OverviewPage/>
                    </UserAuthProvider>
                ),
            },
            {
                path: buildRoute(AppRoute.Profile),
                element: (
                    <ProfilePage/>
                ),
            },
            {
                path: buildRoute(AppRoute.Project),
                loader: async ({params}) => {
                    return {
                        data: (products as unknown as IProject[]).find(
                            (project: IProject) => project.slug === params.slug
                        ),
                        slug: params.slug,
                    }
                },
                children: [
                    // ========================================================================================================
                    // PROJECT
                    {
                        path: buildProjectRoute(ProjectRoute.Dashboard),
                        element: (
                            <ProjectProvider>
                                <ProjectDashboardPage/>
                            </ProjectProvider>
                        ),
                    },
                    {
                        path: buildProjectRoute(ProjectRoute.Activity),
                        element: (
                            <ProjectProvider>
                                <ProjectActivityPage/>
                            </ProjectProvider>
                        ),
                    },
                    {
                        path: buildProjectRoute(ProjectRoute.Analytics),
                        element: (
                            <ProjectProvider>
                                <ProjectAnalyticsPage/>
                            </ProjectProvider>
                        ),
                    },
                    {
                        path: buildProjectRoute(ProjectRoute.Analytics, ProjectAnalyticsRoute.Details),
                        element: (
                            <ProjectProvider>
                                <ProjectAnalyticsDetailsPage/>
                            </ProjectProvider>
                        ),
                    },
                    {
                        path: buildProjectRoute(ProjectRoute.Analytics, ProjectAnalyticsRoute.Setup),
                        element: (
                            <ProjectProvider>
                                <ProjectAnalyticsSetupPage/>
                            </ProjectProvider>
                        ),
                    },
                    {
                        path: buildProjectRoute(ProjectRoute.Analytics, ProjectAnalyticsRoute.ParameterCustomization),
                        element: (
                            <ProjectProvider>
                                <ProjectAnalyticsParameterCustomizationPage/>
                            </ProjectProvider>
                        ),
                    },
                    {
                        path: buildProjectRoute(ProjectRoute.DataSources),
                        element: (
                            <ProjectProvider>
                                <ProjectDataSourcesPage/>
                            </ProjectProvider>
                        ),
                    },
                    {
                        path: buildProjectRoute(ProjectRoute.DataSources, ProjectDataSourcesRoute.AddSensor),
                        element: (
                            <ProjectProvider>
                                <AddProjectDataSourcePage/>
                            </ProjectProvider>
                        ),
                    },
                    {
                        path: buildProjectRoute(ProjectRoute.DataSources, ProjectDataSourcesRoute.Sensor),
                        element: (
                            <ProjectProvider>
                                <ProjectDataSourcePage/>
                            </ProjectProvider>
                        ),
                    },
                    {
                        path: buildProjectRoute(ProjectRoute.Documentation),
                        element: (

                            <ProjectProvider>
                                <ProjectDocumentationPage/>
                            </ProjectProvider>
                        ),
                    },
                    {
                        path: buildProjectRoute(ProjectRoute.Users),
                        element: (
                            <ProjectProvider>
                                <ProjectUsersPage/>
                            </ProjectProvider>
                        ),
                    },
                    {
                        path: buildProjectRoute(ProjectRoute.Guardian),
                        element: (
                            <ProjectProvider>
                                <ProjectGuardianPage/>
                            </ProjectProvider>
                        ),
                    },
                    {
                        path: buildProjectRoute(ProjectRoute.Guardian, ProjectGuardianRoute.Register),
                        element: (
                            <ProjectProvider>
                                <RegisterProjectGuardianPage/>
                            </ProjectProvider>
                        ),
                    },
                ],
            },
        ],
    },
])

export const RouterProvider: FunctionComponent = () => {
    const [state, setState] = useState<IRouterContextData>(null as unknown as IRouterContextData)
    const routerContext: IContext<IRouterContextData> = {
        data: state,
        setData: (_) => {},
    }

    ROUTER.subscribe((state) => {
        const newState: IRouterContextData = { location: state.location }
        setState(newState)
    })

    return (
        <RouterContext.Provider value={routerContext}>
            <UserProvider>
                <_RouterProvider router={ROUTER} />
            </UserProvider>
        </RouterContext.Provider>
    )
}
