import { FunctionComponent } from 'react'
import { Icon, IconName } from '@components/base/Icon'
import { Text } from '@components/base/Text'

interface IEmptyTableRowProps {
    colSpan?: number
}

export const EmptyTableRow: FunctionComponent<IEmptyTableRowProps> = (props) => {
    const { colSpan } = props
    return (
        <tr className='table-row'>
            <td key={0} colSpan={colSpan ?? 1} className='table-cell p-0'>
                <div key={1} className='w-full h-[240px] flex flex-col items-center justify-center'>
                    <Icon name={IconName.Folder} color='text-secondary' />
                    <Text type='subtitle2' color='text-secondary'>
                        No Data
                    </Text>
                </div>
            </td>
        </tr>
    )
}
