import { FunctionComponent } from 'react'
import { useAuth0 } from '@auth0/auth0-react'
import { OnboardingLayout } from '@components/app'
import { Button, Link, Text } from '@components/base'

export const SignInPage: FunctionComponent = () => {
    const { loginWithRedirect } = useAuth0()
    return (
        <OnboardingLayout>
            <Text type='header1' color='text-primary'>
                Sign in to access the dashboard
            </Text>
            <div className='mt-6'>
                <Button size='large' width='full' onClick={() => loginWithRedirect()}>
                    Sign In
                </Button>
                <div className='mt-2'>
                    <Text type='body1' color='text-primary'>
                        Don't have an account?
                    </Text>
                    <Link url='/register'>Request access here</Link>
                </div>
            </div>
        </OnboardingLayout>
    )
}
