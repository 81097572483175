import { ISensorSource } from '@lib/app/sensor'
import { IProjectSlugAccordion } from './api.types'

export class ApiCache {
    private _projects: Array<IProjectSlugAccordion> = []
    private _sensors: Array<ISensorSource> = []

    constructor() {
        if (typeof Storage !== 'undefined') {
            this._projects = JSON.parse(sessionStorage.getItem('projects') || '[]')
            this._sensors = JSON.parse(sessionStorage.getItem('sensors') || '[]')
        }
    }

    public get projects() {
        return this._projects
    }
    public get sensors() {
        return this._sensors
    }

    set(name: string, value: object) {
        if (typeof Storage !== 'undefined') {
            sessionStorage.setItem(name, JSON.stringify(value))
        }
    }

    public clear() {
        if (typeof Storage !== 'undefined') {
            sessionStorage.clear()
        }
    }

    public setProjects(projects: Array<IProjectSlugAccordion>) {
        this.set('projects', projects)
        this._projects = projects
    }

    public setSensors(sensors: Array<ISensorSource>) {
        this.set('sensors', sensors)
        this._sensors = sensors
    }
}
