import { FunctionComponent } from 'react'
import { ITableField, Table } from '@components/base/Table'
import { IIdentityMethod } from '@lib/app/identity'
import { SortDirection, sortPrimitives } from '@lib/utils'
import { IdentityTableRow } from './IdentityTableRow.tsx'

interface IIdentityTableProps {
    loading: boolean
    methods: IIdentityMethod[]
    hiddenColumns?: string[]
}

const IDENTITY_TABLE_FIELDS: ITableField[] = [
    {
        text: 'ID',
        key: 'id',
        sortingFunction: (a: IIdentityMethod, b: IIdentityMethod, direction: SortDirection) =>
            sortPrimitives(a.id, b.id, direction),
        sortByDefault: true,
    },
    {
        text: 'Fragment',
        key: 'fragment',
        sortingFunction: (a: IIdentityMethod, b: IIdentityMethod, direction: SortDirection) =>
            sortPrimitives(a.fragment, b.fragment, direction),
        sortByDefault: true,
    },
    {
        text: 'Type',
        key: 'type',
        sortingFunction: (a: IIdentityMethod, b: IIdentityMethod, direction: SortDirection) =>
            sortPrimitives(a.type_, b.type_, direction),
    },
    {
        text: 'Controller',
        key: 'controller',
        sortingFunction: (a: IIdentityMethod, b: IIdentityMethod, direction: SortDirection) =>
            sortPrimitives(a.controller, b.controller, direction),
    },
]

export const IdentityTable: FunctionComponent<IIdentityTableProps> = (props) => {
    const { loading, methods, hiddenColumns = [] } = props
    const fields = IDENTITY_TABLE_FIELDS.filter((f) => !hiddenColumns.includes(f.key))
    return (
        <Table
            fields={fields}
            data={methods ?? []}
            RowTemplate={IdentityTableRow}
            loading={loading}
            rowTemplateProps={{ hiddenColumns }}
        />
    )
}
