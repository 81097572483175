import { BaseApiService } from '@lib/core/api'
import { INewProjectRequest, IProjectDetails, IProjectNotification, IProjectSites } from '@lib/app/project'
import { IEquipmentDashboardContext } from '@lib/app/sensor'
import { IFile, IListFilesContext, strFromPascalToCamelCase } from '@lib/utils'

export class ContextApiService extends BaseApiService {
    constructor(baseUrl: string) {
        super(baseUrl)
    }

    async getProjects(redirect: boolean = true): Promise<IProjectSites> {
        const overview: IProjectSites = await this.get('api/context/overview', undefined, redirect)

        /**
         * CAUTION: The calculation IDs for every analytics profile (if present) MUST be converted
         * into the correct casing convention (in this case camelCase) so they match the value sets
         * provided each project's details.
         */
        if ('sites' in overview) {
            overview.sites = overview.sites.map((site) => {
                if (site.profiles && site.profiles.length > 0) {
                    site.profiles = site.profiles.map((profile) => {
                        profile.calculations = profile.calculations.map((calculation) => {
                            calculation.id = strFromPascalToCamelCase(calculation.id)
                            return calculation
                        })
                        return profile
                    })
                    return site
                } else {
                    return site
                }
            })
        }

        return overview
    }

    async getProject(id: string): Promise<IProjectDetails> {
        const url = 'api/context/' + id
        return this.get(url)
    }

    async getNotifications(): Promise<{ notifications: IProjectNotification[] }> {
        return this.get('api/context/notifications')
    }

    async addSite(project: INewProjectRequest): Promise<void> {
        return this.post('api/context/new_stream', project)
    }

    async addSensor(id: string, sensor: IEquipmentDashboardContext): Promise<void> {
        return this.post(`api/context/new_sensor/${id}`, sensor)
    }

    async uploadFile(site: string, files: File[]): Promise<IListFilesContext> {
        const url = 'api/context/' + site + '/upload'
        const formData = new FormData()
        ;[...files].forEach((file) => {
            formData.append('files', file)
        })

        try {
            // You can write the URL of your server or any other endpoint used for file upload
            return this.post(url, formData, undefined)
        } catch (e: any) {
            console.error(e)
            return Promise.reject()
        }
    }

    async downloadFile(site: string, name: string): Promise<Blob> {
        const url = 'api/context/' + site + '/files/' + name
        return await this.get(url)
    }

    async deleteFile(site: string, name: string): Promise<void> {
        const url = 'api/context/' + site + '/files/' + name
        return this.delete(url)
    }

    async listFiles(site: string): Promise<IListFilesContext> {
        const url = 'api/context/' + site + '/files'
        return this.get(url)
    }

    async filesMetadata(site: string, _files: IFile[]): Promise<IListFilesContext> {
        const url = 'api/context/' + site + '/files/metadata'
        return this.get(url)
    }
}
