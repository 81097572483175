import { FunctionComponent, MouseEvent, useContext, useRef, useState } from 'react'
import { Button, Icon, IconName, Link, Popover, Text } from '@components/base'
import { FileType } from '@lib/utils'
import { AppSettingsContext } from '@lib/app/settings'

interface IProjectDataSourceTitleProps {
    title: string
    url: string
    canShowMenu?: boolean
    onExportClick?: (fileType: FileType) => void
}

export const ProjectDataSourceTitle: FunctionComponent<IProjectDataSourceTitleProps> = (props) => {
    const { title, url, canShowMenu, onExportClick } = props
    const isDark = useContext(AppSettingsContext).data.theme === 'dark'
    const [showMenu, setShowMenu] = useState(false)
    const [hadOutsideClick, setHadOutsideClick] = useState(false)
    const menuRef = useRef<HTMLDivElement | null>(null)

    function onMenuClick(event?: MouseEvent<HTMLButtonElement>): void {
        if (event?.target) {
            if (hadOutsideClick) {
                // Do nothing.
            } else {
                setShowMenu(!showMenu)
            }
        } else {
            setShowMenu(false)
            setHadOutsideClick(true)
            setTimeout(() => {
                setHadOutsideClick(false)
            }, 200)
        }
    }

    return (
        <div className='w-full flex flex-row items-center justify-between'>
            <Link url={url} icon={IconName.ArrowLeft} color='text-primary' reverse>
                <Text type='header2'>{title}</Text>
            </Link>
            {canShowMenu && (
                <div ref={menuRef} className='w-auto z-10'>
                    <Button variant='outline' size='medium' onClick={onMenuClick}>
                        <Icon name={IconName.Hamburger} color={isDark ? 'text-primary-dark' : 'text-primary'} />
                    </Button>
                    {showMenu && menuRef && (
                        <Popover anchor={menuRef.current as HTMLDivElement} event='click' onOutsideClick={onMenuClick}>
                            <div className='min-w-[300px] flex flex-col justify-between space-y-8 px-4 py-6'>
                                <Text type='header3'>Export</Text>
                                <div className='flex flex-col items-end justify-between space-y-2'>
                                    <Button
                                        variant='outline'
                                        width='3/4'
                                        icon={IconName.Export}
                                        onClick={() => onExportClick && onExportClick('csv')}
                                    >
                                        Export to CSV
                                    </Button>
                                    <Button
                                        variant='outline'
                                        width='3/4'
                                        icon={IconName.Export}
                                        onClick={() => onExportClick && onExportClick('xlsv')}
                                    >
                                        Export to XLSV
                                    </Button>
                                </div>
                            </div>
                        </Popover>
                    )}
                </div>
            )}
        </div>
    )
}
