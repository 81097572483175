import { useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ProjectLayout } from '@components/app'
import { Button, NumberInput, Pane, TextInput } from '@components/base'
import { createApiService } from '@lib/core/api'
import { IEquipmentDashboardContext } from '@lib/app/sensor'
import { config } from '@constants'
import classes from './AddProjectDataSourcePage.module.scss'

export const AddProjectDataSourcePage = () => {
    const { slug } = useParams()

    const navigate = useNavigate()
    const api = createApiService(config.API_BASE_URL)
    const [loading, setLoading] = useState<boolean>(false)

    const addSensorHandler = async (event: React.FormEvent<HTMLFormElement>) => {
        if (loading) {
            return
        }
        event.preventDefault()
        setLoading(true)

        const target = event.target as typeof event.target & {
            sensorId: { value: string }
            group: { value: string }
            units: { value: string }
            eqType: { value: string }
            name: { value: string }
            accuracy: { value: string }
            installed: { value: string }
            serialNo: { value: string }
            manufacturer: { value: string }
        }

        const newSite: IEquipmentDashboardContext = {
            id: target.sensorId.value,
            group: target.group.value,
            units: target.units.value,
            eqType: target.eqType.value,
            name: target.name.value,
            accuracy: parseFloat(target.accuracy.value),
            installed: parseFloat(target.installed.value),
            serialNo: target.serialNo.value,
            manufacturer: target.manufacturer.value,
        }

        api.context
            .addSensor(`${slug}`, newSite)
            .then((response) => {
                console.debug('Response from addProject: ', response)
                navigate(`/projects/${slug}/data-sources`)
            })
            .catch((error) => {
                console.error('Error adding sensor:', error)
            })
            .finally(() => {
                setLoading(false)
            })
    }

    return (
        <ProjectLayout title={`Add Sensor to ${slug}`}>
            <div className={classes.addSource}>
                <Pane>
                    <form onSubmit={addSensorHandler} className='flex flex-col space-y-4'>
                        <TextInput id='sensorId' label='Sensor ID' required autofocus />
                        <TextInput id='group' label='Group' required />
                        <TextInput id='units' label='Units' required />
                        <TextInput id='eqType' label='EQ Type' required />
                        <TextInput id='name' label='Name' required />
                        <NumberInput id='accuracy' label='Accuracy' required />
                        <NumberInput id='installed' label='Installed' required />
                        <TextInput id='serialNo' label='Serial No.' required />
                        <TextInput id='manufacturer' label='Manufacturer' required />
                        <div className='pt-4'>
                            <Button type='submit' width='full' onClick={() => {}}>
                                {loading ? 'Adding...' : 'Add sensor'}
                            </Button>
                        </div>
                    </form>
                </Pane>
            </div>
        </ProjectLayout>
    )
}
