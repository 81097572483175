import { FunctionComponent, ReactNode, useContext } from 'react'
import { Style } from '@lib/utils'
import classes from './Text.module.scss'
import { AppSettingsContext } from '@lib/app/settings'

interface TextProps {
    children: ReactNode
    type: TextType
    justification?: TextJustification
    alignment?: TextAlignment
    color?: string
    freezeColor?: boolean
}

const textTypes = ['header1', 'header2', 'header3', 'header4', 'subtitle1', 'subtitle2', 'body1', 'body2'] as const
type TextType = (typeof textTypes)[number]

const textJustifications = ['left', 'right', 'center', 'justify'] as const
export type TextJustification = (typeof textJustifications)[number]

const textAlignments = ['top', 'middle', 'bottom'] as const
export type TextAlignment = (typeof textAlignments)[number]

/**
 * Renders different types of text to the page, i.e. headers, subtitles,
 * and body paragraphs.
 *
 * @param props
 */
export const Text: FunctionComponent<TextProps> = (props) => {
    const {
        children,
        type,
        justification = 'left',
        alignment = 'middle',
        color = 'text-primary',
        freezeColor = false,
    } = props
    const isDark = useContext(AppSettingsContext).data.theme === 'dark'
    const style: Style = {
        color: `var(--${color}${freezeColor ? '' : isDark ? '-dark' : ''})`,
        textAlign: justification,
        verticalAlign: alignment === 'middle' ? alignment : `text-${alignment}`,
    }

    switch (type) {
        case 'header1':
            return (
                <h1 className={`${classes.header1}`} style={{ ...style }}>
                    {children}
                </h1>
            )
        case 'header2':
            return (
                <h2 className={`${classes.header2}`} style={{ ...style }}>
                    {children}
                </h2>
            )
        case 'header3':
            return (
                <h3 className={`${classes.header3}`} style={{ ...style }}>
                    {children}
                </h3>
            )
        case 'header4':
            return (
                <h4 className={`${classes.header4}`} style={{ ...style }}>
                    {children}
                </h4>
            )
        case 'subtitle1':
            return (
                <p className={`${classes.subtitle1}`} style={{ ...style }}>
                    {children}
                </p>
            )
        case 'subtitle2':
            return (
                <p className={`${classes.subtitle2}`} style={{ ...style }}>
                    {children}
                </p>
            )
        case 'body1':
            return (
                <p className={`${classes.body1}`} style={{ ...style }}>
                    {children}
                </p>
            )
        case 'body2':
        default:
            return (
                <p className={`${classes.body2}`} style={{ ...style }}>
                    {children}
                </p>
            )
    }
}
