import { FunctionComponent, ReactElement, ReactPortal, useContext } from 'react'
import { Footer } from '@components/base/Footer'
import { Header } from '@components/base/Header'
import { Text } from '@components/base/Text'
import classes from '@components/base/Layout/Layout.module.scss'
import { AppSettingsContext } from '@lib/app/settings'
import { ProfileSidebar } from './ProfileSidebar'

type ReactText = string | number
type ReactChild = ReactElement | ReactText

interface ReactNodeArray extends Array<ReactNode> {}
type ReactFragment = {} | ReactNodeArray
type ReactNode = ReactChild | ReactFragment | ReactPortal | boolean | null | undefined

interface ILayoutProps {
    children: React.ReactNode
    title?: string
    TitleComponent?: FunctionComponent
}

export const ProfileLayout: FunctionComponent<ILayoutProps> = (props) => {
    const { children, title, TitleComponent } = props
    const theme = useContext(AppSettingsContext).data.theme
    const isDark = theme === 'dark'

    return (
        <main className={classes['main']}>
            <aside className={`${classes['sidebar-container']} ${isDark ? classes['sidebar-container-dark'] : ''}`}>
                <ProfileSidebar />
            </aside>
            <div className={`${classes['main__content']} ${isDark ? classes['main__content-dark'] : ''}`}>
                <Header />
                <div className={`${classes['main__content-children']}`}>
                    {(title || TitleComponent) && (
                        <div className='flex flex-row items-center justify-between mb-8'>
                            {title && !TitleComponent && <Text type='header2'>{title}</Text>}
                            {TitleComponent && <TitleComponent />}
                        </div>
                    )}
                    {children}
                </div>
                <Footer />
                <div className={`${classes['gradient1']} ${isDark ? classes['gradient1-dark'] : ''}`}></div>
                <div className={`${classes['gradient2']} ${isDark ? classes['gradient2-dark'] : ''}`}></div>
            </div>
        </main>
    )
}
