import { FunctionComponent, ReactNode, useContext } from 'react'
import { Icon, IconName } from '@components/base/Icon'
import { Text } from './Text.tsx'
import { AppSettingsContext } from '@lib/app/settings'

interface InfoTextProps {
    children: ReactNode
}

export const InfoText: FunctionComponent<InfoTextProps> = (props) => {
    const { children } = props
    const isDark = useContext(AppSettingsContext).data.theme === 'dark'
    const color = isDark ? 'text-primary-dark' : 'text-primary'
    return (
        <div className='flex flex-row items-center space-x-3'>
            <Icon name={IconName.Info} color={color} />
            <Text type='subtitle2' color='text-primary'>
                {children}
            </Text>
        </div>
    )
}
