import { FunctionComponent, useContext } from 'react'
import DatePicker from 'react-datepicker'
import { AppSettingsContext } from '@lib/app/settings'
import classes from './DateSelector.module.scss'
import './Calendar.scss'

interface IDateSelectorProps {
    startDate: Date
    endDate: Date
    onDateChange: (startDate: Date, endDate: Date) => void
}

export const DateSelector: FunctionComponent<IDateSelectorProps> = (props) => {
    const { startDate, endDate, onDateChange } = props
    const isDark = useContext(AppSettingsContext).data.theme === 'dark'

    return (
        <div className='flex flex-row items-center bg-[var(--surface-0)] dark:bg-[var(surface-0-dark)] border-[1px] border-[var(--stroke)] dark:border-[var(--stroke-dark)]'>
            <div
                className={`dark:bg-surface-0-dark flex flex-row items-center justify-around ${classes.datepicker} ${isDark ? classes.datepickerDark : ''}`}
            >
                <DatePicker
                    calendarClassName={`calendarStyle${isDark ? 'Dark' : ''}`}
                    popperClassName={`popperStyle${isDark ? 'Dark' : ''}`}
                    selected={startDate}
                    onChange={(date) => date && onDateChange(date, endDate)}
                    selectsStart
                    startDate={startDate}
                    endDate={endDate}
                    maxDate={endDate}
                    dateFormat='MMMM d, yyyy'
                />
                <span>-</span>
                <DatePicker
                    calendarClassName={`calendarStyle${isDark ? 'Dark' : ''}`}
                    popperClassName={`popperStyle${isDark ? 'Dark' : ''}`}
                    selected={endDate}
                    onChange={(date) => date && onDateChange(startDate, date)}
                    selectsEnd
                    startDate={startDate}
                    endDate={endDate}
                    minDate={startDate}
                    maxDate={new Date()}
                    dateFormat='MMMM d, yyyy'
                />
            </div>
        </div>
    )
}
