import { FunctionComponent, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import classes from './ProjectDetails.module.scss'
import { Button, Icon, IconName, Pane, Skeleton, Text } from '@components/base'
import { GoogleMaps } from './GoogleMaps.tsx'
import { IProjectDetails } from '@lib/app/project'
import { getNumberOfSensorsOnline } from '@lib/app/sensor'
import { AppSettingsContext } from '@lib/app/settings'

export const ProjectDetailSkeleton = () => {
    return (
        <article className='flex flex-row items-center gap-[80px] p-4 bg-[var(--surface-1)]'>
            <div className={classes.map}>
                <div style={{ height: '280px', width: '40vw' }} />
            </div>
            <section>
                <h3 className='pt-4'>
                    <Skeleton />
                </h3>
                <div className='flex flex-col space-y-4 mt-6 mb-12'>
                    <div className='flex flex-row items-center space-x-4'>
                        <Icon name={IconName.Location} />
                        <p>
                            <Skeleton width={70} />
                        </p>
                    </div>
                    <div className='flex flex-row items-center space-x-4'>
                        <Icon name={IconName.Cpu} />
                        <p>
                            <Skeleton width={70} />
                        </p>
                    </div>
                    <div className='flex flex-row items-center space-x-4'>
                        <Icon name={IconName.Notifications} />
                        <p>
                            <Skeleton width={70} />
                        </p>
                    </div>
                </div>
            </section>
        </article>
    )
}

export const ProjectDetails: FunctionComponent<IProjectDetails> = (projectDetails) => {
    const navigate = useNavigate()
    const isDark = useContext(AppSettingsContext).data.theme === 'dark'
    const sensorsOnline = getNumberOfSensorsOnline(projectDetails)

    function onViewDashboardClick() {
        console.log('Navigate to project dashboard')
        const url = `/projects/${projectDetails.projectId}`
        navigate(url)
    }

    return (
        <Pane>
            <div className='flex flex-row items-center gap-[80px] p-4'>
                <div style={{ height: '280px', width: '40vw' }}>
                    <GoogleMaps {...projectDetails.location} />
                </div>
                <section>
                    <Text type='header3'>{projectDetails.projectName}</Text>
                    <div className='flex flex-col pt-6'>
                        <Text type='header4'>Stream Address</Text>
                        <Text type='body1'>{projectDetails.projectAnnouncement.slice(0, 10)}...</Text>
                    </div>
                    <div className='flex flex-col space-y-4 pt-6 pb-12'>
                        <div className='flex flex-row items-center space-x-4'>
                            <div
                                className={`${isDark ? classes.iconFixStrokeDark : ''} w-4 flex flex-col items-center justify-center`}
                            >
                                <Icon name={IconName.Location} />
                            </div>
                            <Text type='body1'>{projectDetails.location.address}</Text>
                        </div>
                        <div className='flex flex-row items-center space-x-4'>
                            <div
                                className={`${isDark ? classes.iconFixStrokeDark : ''} w-4 flex flex-col items-center justify-center`}
                            >
                                <Icon name={IconName.Cpu} />
                            </div>
                            <Text type='body1'>{sensorsOnline} Sensors Online</Text>
                        </div>
                        <div className='flex flex-row items-center space-x-4'>
                            <div
                                className={`${isDark ? classes.iconFixFillDark : ''} w-4 flex flex-col items-center justify-center`}
                            >
                                <Icon name={IconName.Notifications} />
                            </div>
                            <Text type='body1'>{projectDetails.notifications.length} Notifications</Text>
                        </div>
                    </div>
                    <Button size='large' onClick={onViewDashboardClick}>
                        View Dashboard
                    </Button>
                </section>
            </div>
        </Pane>
    )
}
