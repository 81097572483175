export function areEqualObjects<T>(o1: T, o2: T): boolean {
    if (o1 === o2) {
        return true
    }

    if (o1 === null || o2 === null || typeof o1 !== 'object' || typeof o2 !== 'object') {
        return false
    }

    const o1Keys = Object.keys(o1)
    const o2Keys = Object.keys(o2)

    if (o1Keys.length !== o2Keys.length) {
        return false
    }

    for (const k of o1Keys) {
        if (!o2Keys.includes(k) || !areEqualObjects((o1 as any)[k], (o2 as any)[k])) {
            return false
        }
    }

    return true
}
