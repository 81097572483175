import { FunctionComponent, useContext, useEffect, useState } from 'react'
import {NavLink, useNavigate, useParams} from 'react-router-dom'
import { config } from '@constants'
import { Icon, IconName } from '@components/base/Icon'
import { Text } from '@components/base/Text'
import { createApiService } from '@lib/core/api'
import { AppRoute, buildRoute } from '@lib/app/routing'
import { UserContext } from '@lib/app/user'
import classes from './SidebarAccordion.module.scss'
import { AppSettingsContext } from '@lib/app/settings'

export const SidebarAcccordion: FunctionComponent = () => {
    const api = createApiService(config.API_BASE_URL)

    const { data } = useContext(UserContext)
    const isDark = useContext(AppSettingsContext).data.theme === 'dark'
    const navigate = useNavigate();

    const [isLoading, setLoading] = useState<boolean>(api.cache.projects.length == 0)
    const [isOpen, setIsOpen] = useState<boolean>(false)
    const [activeProjectName, setActiveProjectName] = useState<string>('')
    const [activeProjectId, setActiveProjectId] = useState<string>('')

    const { slug } = useParams()
    const [projects, setProjects] = useState(api.cache.projects)

    useEffect(() => {
        if (data) {
            const mapped =
                data.sites.map((site) => {
                    return {
                        projectName: site.projectName,
                        slug: `${site.projectId}`,
                        projectId: site.projectId,
                    }
                }) || []
            setLoading(false)
            setProjects(mapped)
        }
    }, [data])

    useEffect(() => {
        api.cache.setProjects(projects)
    }, [projects])

    useEffect(() => {
        const activeProject = projects.find((project) => project.slug === slug)

        if (activeProject) {
            setActiveProjectName(activeProject.projectName)
            setActiveProjectId(activeProject.projectId)
        }
    }, [])

    const toggleAccordion = () => setIsOpen((prev) => !prev)
    const closeAccordion = () => setIsOpen(false)

    return (
        <div className={classes['sidebar-accordion']}>
            <div
                className='flex flex-row items-center justify-between px-8 py-4 cursor-pointer'
                onClick={toggleAccordion}
            >
                <div className='flex flex-col items-start justify-start'>
                    <Text type='body2' color='text-brand-primary' freezeColor>
                        PROJECTS
                    </Text>
                    <Text type='subtitle2' color='text-primary'>
                        {activeProjectName}
                    </Text>
                </div>
                <div className={`${isOpen ? classes.rotate : classes.rotateBack}`}>
                    <Icon name={IconName.ChevronUp} color='text-secondary' />
                </div>
            </div>
            <div
                className={
                    classes['sidebar-accordion-body'] +
                    ' ' +
                    (isOpen && classes['active']) +
                    ' ' +
                    (isDark && classes['sidebar-accordion-body-dark'])
                }
            >
                <div className={classes['links']}>
                    {isLoading ? (
                        <>
                            <div className={classes['skeleton']}>
                                <Icon name={IconName.Location} color='text-secondary' />
                                Loading..
                            </div>
                        </>
                    ) : (
                        projects.map((project, index) => (
                            <NavLink
                                key={index}
                                to={buildRoute(AppRoute.Project).replace(':slug', project.projectId?.toString())}
                                className={() => {
                                    if (project.projectId === activeProjectId) {
                                        return `${classes['active']}`
                                    }
                                }}
                                onClick={() => {
                                    closeAccordion()
                                    setActiveProjectName(project.projectName)
                                    setActiveProjectId(project.projectId)
                                    navigate(buildRoute(AppRoute.Project).replace(':slug', project.projectId?.toString()))
                                }}
                            >
                                <Icon name={IconName.Location} color='text-secondary' />
                                {project.projectName}
                            </NavLink>
                        ))
                    )}
                    <div className={classes['spacer']}></div>
                    <NavLink
                        to={buildRoute(AppRoute.Overview)}
                        end
                        className={({ isActive }) =>
                            isActive ? `${classes['all-projects']} ${classes['active']}` : classes['all-projects']
                        }
                    >
                        <Icon name={IconName.ThreeHorizontalLines} color='text-secondary' />
                        Overview
                    </NavLink>
                </div>
            </div>
        </div>
    )
}
