import { format } from 'date-fns'

export const MS_PER_SECOND = 1000
export const MS_PER_MINUTE = 60 * MS_PER_SECOND
export const MS_PER_HOUR = 60 * MS_PER_MINUTE
export const MS_PER_DAY = 24 * MS_PER_HOUR
export const MS_PER_MONTH = 30 * MS_PER_DAY // rough approximation
export const MS_PER_YEAR = 12 * MS_PER_MONTH

export function dateToReadable(date: Date): string {
    try {
        const now = new Date()

        // TODO: Address the system time issue with the simulator
        if (date.getTime() > now.getTime()) {
            date = new Date(date.getTime() - date.getTimezoneOffset() * 60 * 1000)
        }

        const secondsAgo = Math.floor((now.getTime() - date.getTime()) / 1000)

        if (secondsAgo < 60) {
            return `${secondsAgo} seconds ago`
        }

        const minutesAgo = Math.floor(secondsAgo / 60)
        if (minutesAgo < 60) {
            return `${minutesAgo} minutes ago`
        }

        const hoursAgo = Math.floor(minutesAgo / 60)
        if (hoursAgo < 24) {
            return `${hoursAgo} hours ago`
        }

        // More than a day ago
        return format(date, 'MMM/dd HH:mm:ss')
    } catch (e) {
        console.error(e)
        return '' + date
    }
}

export function formatDate(dateString: string): string {
    try {
        return format(new Date(dateString), 'MMM/dd HH:mm')
    } catch (e) {
        console.debug(e)
        return dateString
    }
}

export function formatDuration(timestamp: number): string {
    const now = new Date()
    const then = new Date(timestamp)
    const elapsed = now.getTime() - then.getTime()

    if (elapsed < MS_PER_MINUTE) {
        return `${Math.round(elapsed / MS_PER_SECOND)}s`
    } else if (elapsed < MS_PER_HOUR) {
        return `${Math.round(elapsed / MS_PER_MINUTE)}min`
    } else if (elapsed < MS_PER_DAY) {
        return `${Math.round(elapsed / MS_PER_HOUR)}h`
    } else if (elapsed < MS_PER_MONTH) {
        return `${Math.round(elapsed / MS_PER_DAY)}d`
    } else if (elapsed < MS_PER_YEAR) {
        return `${Math.round(elapsed / MS_PER_MONTH)}m`
    } else {
        return `${Math.round(elapsed / MS_PER_YEAR)}yrs`
    }
}
