import { FunctionComponent } from 'react'
import { PermissionLabel } from '@components/base/Labels'
import { ITableRowProps, TableRow } from '@components/base/Table'
import { Text } from '@components/base/Text'
import { getUserRoleText, IUser } from '@lib/app/user'

interface IPermissionLabelsCellProps {
    maxLabelsDisplayed: number
    permissions: string[]
}

const PermissionLabelsCell: FunctionComponent<IPermissionLabelsCellProps> = (props) => {
    const { maxLabelsDisplayed, permissions } = props
    const hasMorePermissions = permissions.length > maxLabelsDisplayed
    const excessPermissionCount = hasMorePermissions ? permissions.length - maxLabelsDisplayed : 0
    const permissionLabels = permissions
        .slice(0, maxLabelsDisplayed)
        .map((_, index) => <PermissionLabel permission={permissions[index]} />)
    const labels = [
        ...permissionLabels,
        ...(hasMorePermissions ? [<PermissionLabel permission={`+${excessPermissionCount}`} />] : []),
    ]
    return <div className='flex flex-row space-x-2'>{labels.map((label) => label)}</div>
}

export const ProjectUsersTableRow: FunctionComponent<ITableRowProps<IUser>> = (props) => {
    const { name, role, lastLogin, permissions } = props?.data ?? ({} as IUser)

    const cells = [
        <Text type='body1'>{name}</Text>,
        <Text type='body1'>{getUserRoleText(role)}</Text>,
        <Text type='body1'>{`${lastLogin.getTime()}, ${lastLogin.getDate()}`}</Text>,
        <PermissionLabelsCell maxLabelsDisplayed={3} permissions={permissions} />,
    ]

    return <TableRow>{cells.map((cell) => cell)}</TableRow>
}
