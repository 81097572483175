import { FunctionComponent } from 'react'
import { useParams } from 'react-router-dom'
import { Sidebar } from '@components/base'
import { ProjectSidebarNavigation } from './ProjectSidebarNavigation.tsx'

export const ProjectSidebar: FunctionComponent = () => {
    const { slug } = useParams()
    return (
        <Sidebar>
            <ProjectSidebarNavigation projectSlug={slug ?? ''} />
        </Sidebar>
    )
}
