import { createContext, FunctionComponent, ReactNode, useContext, useEffect, useState } from 'react'
import { NavigateFunction, useNavigate } from 'react-router-dom'
import { useAuth0 } from '@auth0/auth0-react'
import { createApiService } from '@lib/core/api'
import { config } from '@constants'
import { Loading } from '@components/base'
import { AppRoute, buildRoute } from '@lib/app/routing'
import { IUser, UserContext } from '@lib/app/user'

const UserAuthContext = createContext({ state: {}, actions: {} })

export const UserAuthProvider: FunctionComponent<{ children: ReactNode }> = ({ children }) => {
    const { user, isAuthenticated, isLoading, getIdTokenClaims } = useAuth0()
    const [fetching, setFetching] = useState(false)

    const { setData } = useContext(UserContext)

    const api = createApiService(config.API_BASE_URL)

    let navigate: NavigateFunction | null = null
    try {
        navigate = useNavigate()
    } catch (e) {
        console.error(e)
        return <UserAuthContext.Provider value={{ state: {}, actions: {} }}>{children}</UserAuthContext.Provider>
    }

    const getToken = async () => {
        if (isAuthenticated && !fetching) {
            setFetching(true)
            api.auth
                .status()
                .then((status) => {
                    if (!status) {
                        getIdTokenClaims()
                            .then((t) => {
                                const token = t ? t.__raw : ''
                                api.auth
                                    .login(user?.email!, token, token)
                                    .then(() => {
                                        // If were here it means login went well
                                        // And we tried to navigate to /
                                        //navigate!('/overview')
                                        setFetching(false)
                                    })
                                    .catch((error) => {
                                        setFetching(false)
                                        console.error(error)
                                        navigate!(buildRoute(AppRoute.SignIn))
                                    })
                            })
                            .catch((error) => {
                                setFetching(false)
                                console.error(error)
                                window.location.reload()
                            })
                    } else {
                        // ToDo: Remove once the expired token bug is resolved
                        console.debug("Status true")
                        setFetching(false)
                        navigate ? navigate(buildRoute(AppRoute.Overview)) : window.location.reload()
                    }
                })
                .catch((e) => {
                    console.error(e)
                    if (isAuthenticated && user?.email !== undefined) {
                        console.debug("Is authenticated and has email");
                        navigate ? navigate(buildRoute(AppRoute.Overview)) : window.location.reload()
                    } else {
                        console.debug("Is not authenticated or has no email");
                        navigate ? navigate(buildRoute(AppRoute.SignIn)) : window.location.reload()
                    }
                })
        }

        if (!fetching) {
            return <UserAuthContext.Provider value={{ state: {}, actions: {} }}>{children}</UserAuthContext.Provider>
        } else {
            return (
                <UserAuthContext.Provider value={{ state: {}, actions: {} }}>
                    <Loading />
                </UserAuthContext.Provider>
            )
        }
    }

    useEffect(() => {
        const helperFunction = async () => {
            try {
                if (!isLoading && isAuthenticated) {
                    await getToken()
                    setData({ user: user as IUser, sites: [] })
                }
            } catch (err) {
                console.log(err)
                window.location.reload()
            }
        }

        void helperFunction()
    }, [isAuthenticated])

    if (isAuthenticated && user?.email !== undefined) {
        return (
            <UserAuthContext.Provider value={{ state: {}, actions: {} }}>
                {fetching ? <Loading /> : children}
            </UserAuthContext.Provider>
        )
    } else {
        if (isLoading) {
            //window.location.reload();
            if (fetching) {
                return (
                    <UserAuthContext.Provider value={{ state: {}, actions: {} }}>
                        <Loading />
                    </UserAuthContext.Provider>
                )
            }
        }
        return <UserAuthContext.Provider value={{ state: {}, actions: {} }}>{children}</UserAuthContext.Provider>
    }
}
