import { FunctionComponent, ReactNode, useContext } from 'react'
import { signIn, signInDark } from '@assets'
import { Logo } from '@components/base/Logo'
import classes from './OnboardingLayout.module.scss'
import { AppSettingsContext } from '@lib/app/settings'

interface IOnboardingLayoutProps {
    children: ReactNode | ReactNode[]
}

export const OnboardingLayout: FunctionComponent<IOnboardingLayoutProps> = (props) => {
    const { children } = props
    const isDark = useContext(AppSettingsContext).data.theme === 'dark'
    return (
        <main className={classes.onboardingLayout}>
            <article className='flex flex-col pt-12 px-12 bg-surface-1 dark:bg-surface-0-dark'>
                <div>
                    <Logo />
                </div>
                <section className={classes.onboardingContent}>
                    <div className='max-w-[620px] p-12 bg-surface-0 dark:bg-surface-1-dark/15 clipped-xl'>
                        {children}
                    </div>
                </section>
            </article>
            <aside className='h-full'>
                <img
                    className='w-full h-full max-h-[100vh] object-cover'
                    src={isDark ? signInDark : signIn}
                    alt='Sign In image'
                />
            </aside>
        </main>
    )
}
