// All env vars need to be prepended with VITE_ on the system
// Disabled due to: vite.config.ts: __APP_ENV__
// WARN: Potential security issue
import { strToBool } from '@lib/utils'

export const config = {
    DEMO_MODE: strToBool(true, import.meta.env.VITE_DEMO_MODE),
    DEVELOPER_MODE: import.meta.env.DEV || false,
    API_BASE_URL: import.meta.env.VITE_API_BASE_URL || 'http://localhost:8000',
    EXPLORER_URL: import.meta.env.VITE_EXPLORER_URL || 'https://explorer.demia-nodes.net/dashboard/explorer',
    AUTH0_DOMAIN: import.meta.env.VITE_AUTH0_DOMAIN || 'https://signin.demo.demia.net',
    AUTH0_CLIENT_ID: import.meta.env.VITE_AUTH0_CLIENT_ID || 'KJO1MMQW7ae5aQykrpbNKZnyUJb7dsyZ',
    STRIPE_API_KEY:
        import.meta.env.VITE_STRIPE_API_KEY ||
        'pk_test_51PafG7Ano02HsegSRDF8wLqHTtx6ETaIYmUzCoX5YOSuUTzr1G2V59pQIk0yESKrJs73J72T1nvrTOSEXn0cVc6P00Wgt6Yrzl',
}
