import { FunctionComponent } from 'react'
import { IMenuItemProps, Menu } from '@components/base/Menu'

const PROJECT_DATA_SOURCES_TABLE_MENU_ITEMS: IMenuItemProps[] = [
    {
        title: 'Edit',
        onClick: () => {
            console.log('edit data source')
        },
    },
    {
        title: 'Remove',
        onClick: () => {
            console.log('remove data source')
        },
        variant: 'danger',
    },
]

export const ProjectDataSourcesTableMenu: FunctionComponent = () => {
    return <Menu items={PROJECT_DATA_SOURCES_TABLE_MENU_ITEMS} />
}
