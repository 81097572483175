import { FunctionComponent } from 'react'
import { Skeleton, Text } from '@components/base'
import { IProjectInfoData, ISiteLocation } from '@lib/app/project'
import { GoogleMaps } from './GoogleMaps.tsx'

interface IProjectInfoPropsType {
    projectInfo: IProjectInfoData
    location: ISiteLocation
}

export const ProjectInfo: FunctionComponent<IProjectInfoPropsType> = ({ projectInfo, location }) => {
    const { type, methodology, projectDev, projectId } = projectInfo
    return (
        <section className='flex bg-[var(--surface-transparent)] dark:bg-[var(--surface-transparent-dark)] border-[1px] border-[var(--stroke-light)] dark:border-[var(--stroke-light-dark)]'>
            <div style={{ height: '100%', width: '46%' }}>
                <GoogleMaps {...location} />
            </div>
            <div className='flex flex-col space-y-4 px-6 py-8'>
                <div className='space-y-1'>
                    <Text type='body2' color='text-secondary'>
                        Project Type
                    </Text>
                    <Text type='body1'>{type || <Skeleton />}</Text>
                </div>
                <div className='space-y-1'>
                    <Text type='body2' color='text-secondary'>
                        Methodology
                    </Text>
                    <Text type='body1'>{methodology || <Skeleton />}</Text>
                </div>
                <div className='space-y-1'>
                    <Text type='body2' color='text-secondary'>
                        Project Developer
                    </Text>
                    <Text type='body1'>{projectDev || <Skeleton />}</Text>
                </div>
                <div className='space-y-1'>
                    <Text type='body2' color='text-secondary'>
                        Project ID
                    </Text>
                    <Text type='body1'>{projectId || <Skeleton />}</Text>
                </div>
            </div>
        </section>
    )
}
