import { FunctionComponent, MouseEvent, RefObject, useContext } from 'react'
import { Icon, IconName } from '../Icon'
import classes from './IconButton.module.scss'
import { AppSettingsContext } from '@lib/app/settings'

interface IIconButtonProps {
    icon: IconName
    onClick: (event?: MouseEvent<HTMLButtonElement>) => void
    disabled?: boolean
    buttonRef?: RefObject<HTMLButtonElement>
}

export const IconButton: FunctionComponent<IIconButtonProps> = (props) => {
    const { icon, onClick, disabled, buttonRef } = props
    const isDark = useContext(AppSettingsContext).data.theme === 'dark'
    return (
        <button
            type='button'
            ref={buttonRef}
            onClick={onClick}
            disabled={disabled}
            className={`${classes.iconButton} ${isDark ? classes.iconButtonDark : ''} ${disabled ? classes.disabled : ''}`}
        >
            <Icon name={icon} />
        </button>
    )
}
