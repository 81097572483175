import { useContext, useEffect, useState } from 'react'
import { ReactSVG } from 'react-svg'
import { Link, useParams } from 'react-router-dom'
import { pinIcon, molina, arrowLeft, greyArrowRight, greyArrowLeft } from '@assets'
import classes from './SitePlan.module.scss'
import { AppSettingsContext } from '@lib/app/settings'

interface Pin {
    sensorID: string
    sensorSlug: string
    class: string
    class2: string
    title: string
    model?: string
    flow?: string
    average?: string
}

enum View {
    FRONT,
    SIDE,
    BACK,
}

interface ImageMap {
    default: any
    overlays: any[]
    zoom: any[]
    view: View
}

interface Index {
    type: IndexType
    index: number
    view: View
}

enum IndexType {
    DEFAULT,
    OVERLAY,
    ZOOM,
}

const Map = [
    {
        default: molina['0'].default,
        overlays: molina['0'].overlays,
        zoom: molina['0'].zooms,
        view: View.FRONT,
    },
    {
        default: molina['90'].default,
        overlays: molina['90'].overlays,
        zoom: molina['90'].zooms,
        view: View.SIDE,
    },
    {
        default: molina['180'].default,
        overlays: molina['180'].overlays,
        zoom: [],
        view: View.BACK,
    },
]

const sensorData = {
    S1: {
        sensorID: 'Grape Harvest',
        sensorSlug: 'Grape Harvest',
        class: 'agriculturalwaste',
        class2: 'agriculturalwaste',
        title: 'Agricultural Waste',
        model: 'Grape Harvest Waste',
        flow: '185.71 Nm3/h',
        average: '185.23 Nm3/h',
    },
    S2: {
        sensorID: 'Feedstock',
        sensorSlug: 'Feedstock',
        class: 'liquidwaste',
        class2: 'liquidwaste',
        title: 'Liquid Waste',
        model: 'Liquid Industrial Waste',
        flow: '185.71 Nm3/h',
        average: '185.23 Nm3/h',
    },
    S3: {
        sensorID: 'Digestors 1 + 2',
        sensorSlug: 'Digestors 1 + 2',
        class: 'solidwaste',
        class2: 'solidwaste-180',
        title: 'Solid Waste',
        model: 'Local Solid Waste',
        flow: '185.71 tonnes/day',
        average: '185.23 tonnes/day',
    },
    S4: {
        sensorID: 'Flowmeter 4.3',
        sensorSlug: 'Flowmeter 4.3',
        class: 'flowsensor',
        class2: 'flowsensor',
        title: 'Flowmeter 4.3',
        model: 'Esters GD300-12527SIR1000-V4-P0R0',
        flow: '200.71 Nm3/h',
        average: '190.23 Nm3/h',
    },
    S5: {
        sensorID: 'Flowmeter 4.4',
        sensorSlug: 'Flowmeter 4.4',
        class: 'flowsensor-2',
        class2: 'flowsensor-2',
        title: 'Gasflow FT2',
        model: 'Esters GD300-12527SIR1000-V4-P0R0',
        flow: '300.71 Nm3/h',
        average: '280.23 Nm3/h',
    },
}

export const MolinaSensorMap3D = () => {
    const [currentImageIndex, setCurrentImageIndex] = useState<Index>({
        type: IndexType.DEFAULT,
        index: 0,
        view: View.FRONT,
    })
    const [isCloserViewActive, setIsCloserViewActive] = useState(false)
    const [pins, setPins] = useState<Pin[]>([])
    const [isVisibleSensorInfo, setIsVisibleSensorInfo] = useState(false)
    const [openDivId, setOpenDivId] = useState<number>(0)
    const [pointerEventNone, setPointerEventNone] = useState(false)
    const [images, setImages] = useState<ImageMap>(Map[0])
    // TODO: make a limit
    const [prevPages, setPrevPages] = useState<Index[]>([])
    const isDark = useContext(AppSettingsContext).data.theme === 'dark'

    const changeImage = (index: Index) => {
        setCurrentImageIndex(index)
    }

    useEffect(() => {
        const map = Map.find((map) => map.view === currentImageIndex.view)
        setImages(
            map
                ? map
                : {
                      default: molina['0'].default,
                      overlays: molina['0'].overlays,
                      zoom: molina['0'].zooms,
                      view: View.FRONT,
                  }
        )
    }, [currentImageIndex])

    const getImage = () => {
        switch (currentImageIndex.type) {
            case IndexType.DEFAULT:
                return images.default
            case IndexType.OVERLAY:
                return images.overlays[currentImageIndex.index]
                    ? images.overlays[currentImageIndex.index]
                    : images.default
            case IndexType.ZOOM:
                return images.zoom[currentImageIndex.index] ? images.zoom[currentImageIndex.index] : images.default
            default:
                return images.default
        }
    }

    const area1handler = (index: number) => {
        setImages(Map[0])
        setIsCloserViewActive(true)
        setPrevPages([currentImageIndex, ...prevPages])
        changeImage({ index, type: IndexType.ZOOM, view: View.FRONT })

        //let data = [];
        if (index === 0) {
            setPins([sensorData.S1])
        } else if (index === 1) {
            setPins([sensorData.S2])
        } else if (index === 2) {
            setPins([sensorData.S3])
        }
    }

    const area2handler = () => {
        setImages(Map[1])
        setIsCloserViewActive(true)
        setPrevPages([currentImageIndex, ...prevPages])
        setCurrentImageIndex({ index: 0, type: IndexType.ZOOM, view: View.SIDE })

        setPins([sensorData.S4, sensorData.S5])
    }

    const area3handler = (index: number) => {
        setImages(Map[2])
        setIsCloserViewActive(true)
        setPrevPages([currentImageIndex, ...prevPages])
        let view = View.FRONT
        let newIndex = index
        if (index === 0) {
            setPins([sensorData.S1])
        } else if (index === 1) {
            setPins([sensorData.S2])
        } else if (index === 2) {
            setPins([sensorData.S3])
        } else if (index === 3) {
            view = View.SIDE
            newIndex = 2
            setPins([sensorData.S4])
        }
        setCurrentImageIndex({ index: newIndex, type: IndexType.ZOOM, view })
    }

    const goToPreviousImage = () => {
        setPrevPages([currentImageIndex, ...prevPages])

        let index = 0
        switch (currentImageIndex.type) {
            case IndexType.DEFAULT:
                break
            case IndexType.ZOOM:
                index = currentImageIndex.index === 0 ? images.zoom.length - 1 : currentImageIndex.index - 1
                break
            case IndexType.OVERLAY:
                index = currentImageIndex.index === 0 ? images.overlays.length - 1 : currentImageIndex.index - 1
                break
        }

        let view = currentImageIndex.view

        if (currentImageIndex.type === IndexType.DEFAULT) {
            switch (currentImageIndex.view) {
                case View.BACK:
                    view = View.SIDE
                    break
                case View.SIDE:
                    view = View.FRONT
                    break
                case View.FRONT:
                    view = View.BACK
                    break
            }
        } else {
            switch (currentImageIndex.view) {
                case View.BACK:
                    area3handler(index)
                    break
                case View.FRONT:
                    area1handler(index)
                    break
                case View.SIDE:
                    area2handler()
            }
        }

        setCurrentImageIndex({ view, type: currentImageIndex.type, index })
    }

    const goToNextImage = () => {
        setPrevPages([currentImageIndex, ...prevPages])

        let index = 0
        switch (currentImageIndex.type) {
            case IndexType.DEFAULT:
                break
            case IndexType.ZOOM:
                index = currentImageIndex.index === images.zoom.length - 1 ? 0 : currentImageIndex.index + 1
                break
            case IndexType.OVERLAY:
                index = currentImageIndex.index === images.overlays.length - 1 ? 0 : currentImageIndex.index + 1
                break
        }

        let view = currentImageIndex.view
        if (currentImageIndex.type === IndexType.DEFAULT) {
            switch (currentImageIndex.view) {
                case View.BACK:
                    view = View.FRONT
                    break
                case View.SIDE:
                    view = View.BACK
                    break
                case View.FRONT:
                    view = View.SIDE
                    break
            }
        } else {
            switch (currentImageIndex.view) {
                case View.BACK:
                    area3handler(index)
                    break
                case View.FRONT:
                    area1handler(index)
                    break
                case View.SIDE:
                    area2handler()
            }
        }

        setCurrentImageIndex({ view, type: currentImageIndex.type, index })
    }

    const backButtonClickHandler = () => {
        const newCurrentIndex = prevPages.pop() || { view: View.FRONT, type: IndexType.DEFAULT, index: 0 }
        setPrevPages([...prevPages])

        setCurrentImageIndex(newCurrentIndex)

        setIsCloserViewActive(newCurrentIndex.type === IndexType.ZOOM)
        setIsVisibleSensorInfo(false)
    }

    const showSensorInfo = (index: number) => {
        setIsVisibleSensorInfo((prev) => !prev)
        setOpenDivId(openDivId === index ? openDivId : index)
        setPointerEventNone((prev) => !prev)
    }

    const { slug } = useParams()
    const url = `/projects/${slug}/data-sources`

    let imageMap

    if (currentImageIndex.view === View.FRONT) {
        imageMap = (
            <svg className={classes.svgOverlay} viewBox='0 0 1420 632'>
                <polygon
                    points='129,229,128,152,455,151,498,121,514,120,554,148,687,155,686,224,444,239,439,212,425,211,418,176,333,173,346,209,285,217,288,246,276,249'
                    className={classes.polygon}
                    onClick={() => area1handler(0)}
                    onMouseEnter={(e) => {
                        e.preventDefault()
                        changeImage({ type: IndexType.OVERLAY, index: 2, view: View.FRONT })
                    }}
                    onMouseLeave={(e) => {
                        e.preventDefault()
                        changeImage({ type: IndexType.DEFAULT, index: 0, view: View.FRONT })
                    }}
                />
                <polygon
                    points='209,295,349,298,474,263,441,260,440,227,431,227,421,193,337,192,346,227,315,232,288,234,289,275'
                    className={classes.polygon}
                    onClick={() => area1handler(1)}
                    onMouseEnter={(e) => {
                        e.preventDefault()
                        changeImage({ type: IndexType.OVERLAY, index: 1, view: View.FRONT })
                    }}
                    onMouseLeave={(e) => {
                        e.preventDefault()
                        changeImage({ type: IndexType.DEFAULT, index: 0, view: View.FRONT })
                    }}
                />
                <polygon
                    points='675,272,676,334,864,369,949,348,950,284,763,258'
                    className={classes.polygon}
                    onClick={() => area1handler(2)}
                    onMouseEnter={(e) => {
                        e.preventDefault()
                        changeImage({ type: IndexType.OVERLAY, index: 0, view: View.FRONT })
                    }}
                    onMouseLeave={(e) => {
                        e.preventDefault()
                        changeImage({ type: IndexType.DEFAULT, index: 0, view: View.FRONT })
                    }}
                />
            </svg>
        )
    } else if (currentImageIndex.view === View.SIDE) {
        imageMap = (
            <svg className={classes.svgOverlay} viewBox='0 0 1420 632'>
                <polygon
                    points='756,485,786,485,787,526,752,529'
                    className={classes.polygon}
                    //style={{strokeWidth: currentImageIndex.type === IndexType.OVERLAY && currentImageIndex.index === 3 ? 2 : 0}}
                    onClick={area2handler}
                    onMouseEnter={(e) => {
                        e.preventDefault()
                        changeImage({ type: IndexType.OVERLAY, index: 3, view: View.SIDE })
                    }}
                    onMouseLeave={(e) => {
                        e.preventDefault()
                        changeImage({ type: IndexType.DEFAULT, index: 0, view: View.SIDE })
                    }}
                />
                <polygon
                    points='626,490,667,491,668,536,632,537'
                    className={classes.polygon}
                    //style={{strokeWidth: currentImageIndex.type === IndexType.OVERLAY && currentImageIndex.index === 2 ? 2 : 0}}
                    onClick={area2handler}
                    onMouseEnter={(e) => {
                        e.preventDefault()
                        changeImage({ type: IndexType.OVERLAY, index: 2, view: View.SIDE })
                    }}
                    onMouseLeave={(e) => {
                        e.preventDefault()
                        changeImage({ type: IndexType.DEFAULT, index: 0, view: View.SIDE })
                    }}
                />
            </svg>
        )
    } else if (images.view === View.BACK) {
        imageMap = (
            <svg className={classes.svgOverlay} viewBox='0 0 1420 632'>
                <polygon
                    points='1184,191,1241,186,1256,115,1417,108,1417,261'
                    className={classes.polygon}
                    //style={{strokeWidth: currentImageIndex.type === IndexType.OVERLAY && currentImageIndex.index === 0 ? 2 : 0}}
                    onClick={() => area3handler(0)}
                    onMouseEnter={(e) => {
                        e.preventDefault()
                        changeImage({ type: IndexType.OVERLAY, index: 0, view: View.BACK })
                    }}
                    onMouseLeave={(e) => {
                        e.preventDefault()
                        changeImage({ type: IndexType.DEFAULT, index: 0, view: View.BACK })
                    }}
                />
                <polygon
                    points='846,167,870,168,872,152,890,154,886,142,921,130,938,162,950,162,951,187,959,188,960,179,970,186,989,199,992,212,973,219,946,222,844,207'
                    className={classes.polygon}
                    //style={{strokeWidth: currentImageIndex.type === IndexType.OVERLAY && currentImageIndex.index === 1 ? 2 : 0}}
                    onClick={() => area3handler(1)}
                    onMouseEnter={(e) => {
                        e.preventDefault()
                        changeImage({ type: IndexType.OVERLAY, index: 1, view: View.BACK })
                    }}
                    onMouseLeave={(e) => {
                        e.preventDefault()
                        changeImage({ type: IndexType.DEFAULT, index: 0, view: View.BACK })
                    }}
                />
                <polygon
                    points='430,209,448,208,451,237,433,238'
                    className={classes.polygon}
                    //style={{strokeWidth: currentImageIndex.type === IndexType.OVERLAY && currentImageIndex.index === 2 ? 2 : 0}}
                    onClick={() => area3handler(2)}
                    onMouseEnter={(e) => {
                        e.preventDefault()
                        changeImage({ type: IndexType.OVERLAY, index: 2, view: View.BACK })
                    }}
                    onMouseLeave={(e) => {
                        e.preventDefault()
                        changeImage({ type: IndexType.DEFAULT, index: 0, view: View.BACK })
                    }}
                />
                <polygon
                    points='423,290,442,290,441,305,425,305'
                    className={classes.polygon}
                    //style={{strokeWidth: currentImageIndex.type === IndexType.OVERLAY && currentImageIndex.index === 4 ? 2 : 0}}
                    onClick={() => area3handler(3)}
                    onMouseEnter={(e) => {
                        e.preventDefault()
                        changeImage({ type: IndexType.OVERLAY, index: 4, view: View.BACK })
                    }}
                    onMouseLeave={(e) => {
                        e.preventDefault()
                        changeImage({ type: IndexType.DEFAULT, index: 0, view: View.BACK })
                    }}
                />
            </svg>
        )
    }

    return (
        <article className={classes.sitePlan}>
            <div className={classes.content}>
                <div className={`${classes.innerContent} ${isDark && classes.innerContentDark}`}>
                    <div className={`${classes.image3dInner} ${isDark && classes.image3dInnerDark}`}>
                        {!isCloserViewActive ? (
                            <>
                                <img
                                    src={getImage()}
                                    alt='location-map'
                                    className={classes.imageMolina}
                                    useMap={`#location-map-${currentImageIndex}`}
                                />
                                {imageMap}
                            </>
                        ) : (
                            <>
                                <img src={getImage()} alt='image' className={classes.imageMolina} />
                                {pins.map((pin, index) => {
                                    const pinClass = currentImageIndex.view === View.FRONT ? pin.class : pin.class2
                                    return (
                                        <div className={`${classes.pinContainer} ${classes[pinClass]}`} key={index}>
                                            <ReactSVG
                                                src={pinIcon}
                                                className={`${classes.pin} ${
                                                    openDivId !== index && pointerEventNone ? classes.pointerEvent : ''
                                                }`}
                                                onClick={() => showSensorInfo(index)}
                                            />
                                            {openDivId === index && (
                                                <div
                                                    className={`${classes.sensorInfo} ${
                                                        isVisibleSensorInfo ? classes.visible : ''
                                                    }`}
                                                >
                                                    <div className={classes.sensorTitleContainer}>
                                                        <h3>{pin.title}</h3>
                                                    </div>
                                                    <div className={classes.infoContainer}>
                                                        <div className={classes.singleItem}>
                                                            <h4>Sensor ID</h4>
                                                            <p>{pin.sensorID}</p>
                                                        </div>
                                                        <div className={classes.singleItem}>
                                                            <h4>Model</h4>
                                                            <p>{pin.model}</p>
                                                        </div>
                                                        {/*<div className={classes.singleItem}>
                <h4>Current flow</h4>
                <p>{pin.flow}</p>
              </div>
              <div className={classes.singleItem}>
                <h4>Daily average flow</h4>
                <p>{pin.average}</p>
              </div>*/}
                                                    </div>
                                                    <Link
                                                        to={`${url}/${pin.sensorSlug}`}
                                                        className={classes.sensorLink}
                                                    >
                                                        View sensor
                                                    </Link>
                                                </div>
                                            )}
                                        </div>
                                    )
                                })}
                                <button onClick={backButtonClickHandler} className={classes.backButton}>
                                    <ReactSVG src={arrowLeft} className={classes.icon} />
                                    Back
                                </button>
                            </>
                        )}
                        <div className={classes.buttons}>
                            <button onClick={goToPreviousImage}>
                                <ReactSVG src={greyArrowLeft} className={classes.icon} />
                            </button>
                            <span></span>
                            <button onClick={goToNextImage}>
                                <ReactSVG src={greyArrowRight} className={classes.icon} />
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </article>
    )
}
