import { useLocation, useParams } from 'react-router-dom'
import { cargoAverage, cargoCondidence, cargoCurrent, flowmeter, statusConnected, statusDisconnected } from '@assets'
import { ProjectLayout, ProjectSensorDataTable } from '@components/app'
import classes from './Flowmeter.module.scss'
import { FunctionComponent, useContext, useEffect, useState } from 'react'
import { dateToReadable, FileType } from '@lib/utils'
import { BarRangeChart, Pane, Skeleton, Tabs, Text } from '@components/base'
import { getSensorDataFromDashboardContext, ISensorContextData, ISensorData } from '@lib/app/sensor'
import { ProjectDataSourceTitle } from '../ProjectDataSourceTitle'
import { AppSettingsContext } from '@lib/app/settings'
import {IChartData} from "@components/base/Charts/types.ts";

const flowDataEmpty = {
    image: flowmeter,
    active: true,
    infoData: [
        {
            title: 'Sensor ID',
            value: '',
        },
        {
            title: 'Accuracy',
            value: '',
        },
        {
            title: 'Manufacturer',
            value: '',
        },
        {
            title: 'Installed on',
            value: '',
        },
        {
            title: 'Model',
            value: '',
        },
        {
            title: 'Last Calibration',
            value: '',
        },
        {
            title: 'Serial Number',
            value: '',
        },
        {
            title: 'Next Calibration',
            value: '',
        },
    ],
}

const flowInfo = {
    source: 'Flowmeter 1',
    lastUpdate: '27 Oct 2023 - 6:45 PM',
    unit: '0.05 Nm3',
}

const flowmeterDataEmpty = [
    {
        img: cargoCurrent,
        text: 'Current load',
        value: 0,
        valueUnit: '',
    },
    {
        img: cargoAverage,
        text: 'Daily average load',
        value: 0,
        valueUnit: '',
    },
    {
        img: cargoCondidence,
        text: 'Data confidence',
        value: 0,
        valueUnit: '%',
    },
]

export const FlowmeterDataSource: FunctionComponent<{ sensor: ISensorContextData | undefined }> = (props) => {
    const [activeTab, setActiveTab] = useState(0)

    const { slug } = useParams()
    const url = `/projects/${slug}/data-sources`
    const isDark = useContext(AppSettingsContext).data.theme === 'dark'

    const { sensor } = props

    const location = useLocation()
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }, [location])

    const thirtyDaysAgo = new Date()
    thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30)

    const [flowmeterData, setFlowmeterData] = useState<
        Array<{
            img: string
            text: string
            value: number
            valueUnit: string
        }>
    >(
        flowmeterDataEmpty.map((a) => {
            return { ...a }
        })
    )

    const [flowData, setFlowData] = useState(
        flowDataEmpty.infoData.map((a) => {
            return { ...a }
        })
    )

    const [sensorDataList, setSensorDataList] = useState<ISensorData[]>([])
    const [graphData, setGraphData] = useState<
        Array<IChartData>
    >([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if (sensor) {
            setGraphData(
                Array.from(Object.values(sensor.readings)).map((v) => {
                    return {
                        name: new Date(v.timestamp),
                        uv: v.value,
                        pv: v.value,
                        amt: v.value,
                    }
                })
            )

            const readings = Array.from(Object.values(sensor.readings)).map((v) => {
                return getSensorDataFromDashboardContext(v, sensor.equipment.units)
            })

            setSensorDataList(readings)
            setLoading(false)
        }
    }, [sensor])

    useEffect(() => {
        if (sensor) {
            const numbers = [...flowmeterData]
            numbers[0].value = Math.round(sensor.total * 100000) / 100000
            // TEMP
            numbers[0].valueUnit = 'm3/hour' /*props.sensor.sensor.equipment.units*/
            numbers[1].value = Math.round(sensor.total * 100000) / 100000
            // TEMP
            numbers[1].valueUnit = 'm3/hour' /*props.sensor.sensor.equipment.units*/
            numbers[2].value = Math.round(sensor.avgcf * 10000) / 10000
            setFlowmeterData(numbers)

            const newData = [...flowData]
            newData[0].value = sensor.equipment.id
            newData[1].value = sensor.equipment.accuracy + ''
            newData[2].value = sensor.equipment.manufacturer
            newData[3].value = sensor.equipment.installed + ''
            newData[4].value = sensor.equipment.eqType
            // Last calibration
            newData[5].value = 'Never'
            newData[6].value = sensor.equipment.serialNo
            // Next calibration
            newData[7].value = 'Soon'
            setFlowData(newData)
        }
    }, [sensor])

    function onExportClick(fileType: FileType): void {
        console.log(fileType)
    }

    const TitleComponent = () => {
        return (
            <ProjectDataSourceTitle
                title={sensor?.equipment.name ?? 'Flowmeter'}
                url={url}
                canShowMenu={activeTab === 1}
                onExportClick={onExportClick}
            />
        )
    }

    return (
        <ProjectLayout TitleComponent={TitleComponent}>
            <Tabs tabHeaders={['Overview', 'Logs']} onTabClick={setActiveTab}>
                <article className={classes.flowmeterWrapper}>
                    <div className={`${classes.flowmeterInfo} ${isDark && classes.flowmeterInfoDark}`}>
                        <figure className={classes.image}>
                            <img src={flowDataEmpty.image} alt='Flow Image' />
                        </figure>
                        <div className={`${classes.rightSide} ${isDark && classes.rightSideDark}`}>
                            <img
                                src={flowDataEmpty.active ? statusConnected : statusDisconnected}
                                className={classes.status}
                                alt='Status'
                            />
                            <div className={classes.data}>
                                {flowData.map((data, index) => (
                                    <div className={classes.item} key={index}>
                                        <h6>{data.title}</h6>
                                        <p>{data.value || <Skeleton />}</p>
                                    </div>
                                ))}
                            </div>
                        </div>
                        <div className='flex flex-row space-x-4'>
                            {flowmeterData.map((data, index) => (
                                <div
                                    className='w-1/3 bg-[var(--surface-0)] dark:bg-[var(--surface-0-dark)] border-[1px] border-[var(--stroke-light)] dark:border-[var(--stroke-light-dark)] flex flex-col items-center justify-around p-6 space-y-4'
                                    key={index}
                                >
                                    <img src={data.img} alt='Image' />
                                    <Text type='body1' color='text-secondary'>
                                        {data.text}
                                    </Text>
                                    <h5 className='font-32-28-500 text-text-primary dark:text-text-primary-dark'>
                                        {data.value}{' '}
                                        <span className='font-24-28-400 text-[var(--text-secondary)]'>
                                            {data.valueUnit}
                                        </span>
                                    </h5>
                                </div>
                            ))}
                        </div>
                        <div className={`${classes.bottom} ${isDark && classes.bottomDark}`}>
                            <h4>Biogas Flow</h4>
                            <div className={classes.info}>
                                <p>
                                    Source: <span>{flowInfo.source}</span>
                                </p>
                                <p>
                                    Last update:{' '}
                                    <span>{sensor ? dateToReadable(new Date(sensor.lastUpdated)) : 'never'}</span>
                                </p>
                                <p>
                                    Unit: <span>{flowInfo.unit}</span>
                                </p>
                            </div>
                        </div>
                    </div>
                    <Pane>
                        <div className='p-2'>
                            <BarRangeChart
                                title={sensor && { title: sensor.equipment.name }}
                                data={graphData}
                                loading={loading}
                            />
                        </div>
                    </Pane>
                </article>
                <ProjectSensorDataTable loading={loading} sensorDataList={sensorDataList} />
            </Tabs>
        </ProjectLayout>
    )
}
