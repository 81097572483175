import { FunctionComponent, MouseEvent, useContext, useState } from 'react'
import { useDropzone } from 'react-dropzone'
import { useParams } from 'react-router-dom'
import { Button } from '@components/base/Button'
import { Icon, IconName } from '@components/base/Icon'
import { Popup } from '@components/base/Popup'
import { Text } from '@components/base/Text'
import { config } from '@constants'
import { IProjectDocument } from '@lib/app/project'
import { createApiService } from '@lib/core/api'
import { AppSettingsContext } from '@lib/app/settings'

interface ModalProps {
    onClose: (newDocuments: IProjectDocument[]) => void
}

const api = createApiService(config.API_BASE_URL)

export const ProjectDocumentationPopup: FunctionComponent<ModalProps> = (props) => {
    const { onClose } = props
    const { slug } = useParams()

    const isDark = useContext(AppSettingsContext).data.theme === 'dark'

    const [uploadedFiles, setUploadedFiles] = useState<File[]>([])
    const { getRootProps, getInputProps } = useDropzone({
        onDrop: async (acceptedFiles: File[]) => {
            setUploadedFiles(acceptedFiles)
            const newFiles = await api.context.uploadFile(slug!, acceptedFiles)
            const documents: IProjectDocument[] = newFiles.files.map((s) => {
                return {
                    file: {
                        ...s,
                        lastModified: new Date(s.lastModified),
                    },
                }
            })
            onClose(documents)
        },
    })

    function onCancelClick(e?: MouseEvent<HTMLButtonElement>): void {
        e?.stopPropagation && e?.stopPropagation()
        onClose([])
    }

    function onBrowseFilesClick(): void {
        // Do nothing.
    }

    return (
        <Popup onClose={() => onClose([])}>
            <div
                {...getRootProps()}
                className='flex flex-col items-center justify-between space-y-2 border-[1px] border-dashed border-[var(--grey)] m-4 py-6 px-20'
            >
                <div className='mb-3'>
                    <Icon name={IconName.Upload} color={isDark ? 'text-brand-primary-dark' : 'text-brand-primary'} />
                </div>
                <input {...getInputProps()} />
                <Text type='subtitle2' color={isDark ? 'text-secondary' : 'grey'}>
                    Drag and Drop Files Here,
                </Text>
                <Text type='subtitle2' color={isDark ? 'text-secondary' : 'grey'}>
                    or
                </Text>
                <ul>
                    {uploadedFiles.map((file: File) => (
                        <li key={file.name}>
                            <Text type='body1' color={isDark ? 'text-secondary' : 'grey'}>
                                {file.name}
                            </Text>
                        </li>
                    ))}
                </ul>
                <div className='flex flex-row items-center justify-around space-x-2'>
                    <Button variant='text' size='medium' onClick={onCancelClick}>
                        Cancel
                    </Button>
                    <Button variant='primary' size='medium' onClick={onBrowseFilesClick}>
                        Browse Files
                    </Button>
                </div>
            </div>
        </Popup>
    )
}
