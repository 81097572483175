import { IFile } from '@lib/utils'
import { IProjectDocument } from './project.types.ts'

export function updateProjectDocumentFile(document: IProjectDocument, file: IFile): IProjectDocument {
    return {
        ...document,
        file: {
            ...file,
            lastModified: new Date(file.lastModified),
        },
    }
}

export function getDatedTotal(data: [Date, number][], startDate: Date, endDate: Date): string {
    const sum = 0
    return data
        .filter(([name, _]) => {
            const itemDate = new Date(name)
            return itemDate >= startDate && itemDate <= endDate
        })
        .map(([_, val]) => val)
        .reduce((acc, val) => acc + val, sum)
        .toFixed(3)
}
