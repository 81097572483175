import ReactDOM from 'react-dom/client'
import { UserAuthRedirectProvider } from '@components/app'
import App from './App'
import './index.scss'

ReactDOM.createRoot(document.getElementById('root')!).render(
    //<React.StrictMode>
    <UserAuthRedirectProvider>
        <App />
    </UserAuthRedirectProvider>
    //</React.StrictMode>
)
