import { useLocation, useParams } from 'react-router-dom'
import { cargoAverage, cargoCondidence, cargoCurrent, grapeHarvest, greenWaste } from '@assets'
import { FunctionComponent, useEffect, useState } from 'react'
import { dateToReadable } from '@lib/utils'
import { ProjectLayout, ProjectSensorDataTable } from '@components/app'
import { BarChart, Pane, Skeleton, Tabs, Text } from '@components/base'
import { getSensorDataFromDashboardContext, ISensorContextData, ISensorData } from '@lib/app/sensor'
import { ProjectDataSourceTitle } from '../ProjectDataSourceTitle'
import {IChartData} from "@components/base/Charts/types.ts";

const cargoDataEmpty: Array<{
    img: string
    text: string
    value: number
    valueUnit: string
}> = [
    {
        img: cargoCurrent,
        text: 'Current Load',
        value: 0,
        valueUnit: 'Kg',
    },
    {
        img: cargoAverage,
        text: 'Daily Average Load',
        value: 0,
        valueUnit: 'Kg',
    },
    {
        img: cargoCondidence,
        text: 'Data Confidence',
        value: 0,
        valueUnit: '%',
    },
]

export const CargoDataSource: FunctionComponent<{ sensor: ISensorContextData }> = (props) => {
    const [activeTab, setActiveTab] = useState(0)

    const { slug } = useParams()
    const url = `/projects/${slug}/data-sources`

    const { sensor } = props

    const location = useLocation()
    useEffect(() => {
        window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })
    }, [location])

    const thirtyDaysAgo = new Date()
    thirtyDaysAgo.setDate(thirtyDaysAgo.getDate() - 30)

    const [cargoData, setCargoData] = useState<
        Array<{
            img: string
            text: string
            value: number
            valueUnit: string
        }>
    >([{ ...cargoDataEmpty[0] }, { ...cargoDataEmpty[1] }, { ...cargoDataEmpty[2] }])

    const [tableList, setTableList] = useState<ISensorData[]>([])
    const [graphData, setGraphData] = useState<
        Array<IChartData>
    >([])
    const [loading, setLoading] = useState(true)

    useEffect(() => {
        if (sensor) {
            setGraphData(
                Array.from(Object.values(sensor.readings)).map((v) => {
                    return {
                        name: new Date(v.timestamp),
                        uv: v.value,
                        pv: v.value,
                        amt: v.value,
                    }
                })
            )

            const readings = Array.from(Object.values(sensor.readings)).map((v) => {
                return getSensorDataFromDashboardContext(v, sensor.equipment.units)
            })

            readings.sort((a, b) => b.timestamp.getTime() - a.timestamp.getTime())
            setTableList(readings)
            setLoading(false)
        }
    }, [sensor])

    useEffect(() => {
        if (sensor) {
            const numbers = [...cargoData]
            numbers[0].value = Math.round(sensor.total)
            numbers[0].valueUnit = sensor.equipment.units
            numbers[1].value = Math.round(sensor.avgcf)
            numbers[1].valueUnit = sensor.equipment.units
            numbers[2].value = Math.round(sensor.avgcf)
            setCargoData(numbers)
        }
    }, [sensor])

    function onExportClick(fileType: 'csv' | 'xlsv'): void {
        console.log(fileType)
    }

    const TitleComponent = () => {
        return (
            <ProjectDataSourceTitle
                title={sensor?.equipment.name ?? 'Cargo'}
                url={url}
                canShowMenu={activeTab === 1}
                onExportClick={onExportClick}
            />
        )
    }

    return (
        <ProjectLayout TitleComponent={TitleComponent}>
            <Tabs tabHeaders={['Overview', 'Logs']} onTabClick={setActiveTab}>
                <article className='flex flex-col space-y-8'>
                    <div className='flex flex-row items-center justify-between space-x-4 p-8 clipped bg-surface-transparent dark:bg-surface-transparent-dark'>
                        <div className='max-w-[320px]'>
                            {sensor ? (
                                sensor.id === 'Grape Harvest' ? (
                                    <img className='aspect-square object-cover' src={grapeHarvest} alt='grapeHarvest' />
                                ) : (
                                    <img className='aspect-square object-cover' src={greenWaste} alt='greenWaste' />
                                )
                            ) : (
                                <Skeleton width={320} height={320} />
                            )}
                        </div>
                        <div className='w-full flex flex-col space-y-4 justify-between'>
                            <div className='w-full flex flex-row items-center justify-between space-x-4'>
                                {cargoData.map((data, index) => (
                                    <div
                                        className='w-1/3 h-full bg-[var(--surface-0)] dark:bg-[var(--surface-0-dark)] border-[1px] border-[var(--stroke-light)] dark:border-[var(--stroke-light-dark)] flex flex-col items-center justify-between p-6 space-y-4'
                                        key={index}
                                    >
                                        <img src={data.img} alt='Image' />
                                        <Text type='body1' color='text-secondary'>
                                            {data.text}
                                        </Text>
                                        <h5 className='font-32-28-500 text-text-primary dark:text-text-primary-dark'>
                                            {data.value}{' '}
                                            <span className='font-24-28-400 text-[var(--text-secondary)]'>
                                                {data.valueUnit}
                                            </span>
                                        </h5>
                                    </div>
                                ))}
                            </div>
                            <div className='w-full bg-[var(--surface-0)] dark:bg-[var(--surface-0-dark)] border-[1px] border-[var(--stroke-light)] dark:border-[var(--stroke-light-dark)] p-6'>
                                <Text type='header3'>Solid Waste Load</Text>
                                <div className='w-full flex flex-row items-center justify-between mt-6'>
                                    <div className='flex flex-row items-center space-x-2'>
                                        <Text type='body1' color='text-secondary'>
                                            Source:
                                        </Text>
                                        <Text type='subtitle1'>{sensor?.equipment.id || <Skeleton width={64} />}</Text>
                                    </div>
                                    <div className='flex flex-row items-center space-x-2'>
                                        <Text type='body1' color='text-secondary'>
                                            Last updated:
                                        </Text>
                                        <Text type='subtitle1'>
                                            {sensor ? (
                                                dateToReadable(new Date(sensor.lastUpdated))
                                            ) : (
                                                <Skeleton width={64} />
                                            )}
                                        </Text>
                                    </div>
                                    <div className='flex flex-row items-center space-x-2'>
                                        <Text type='body1' color='text-secondary'>
                                            Units:
                                        </Text>
                                        <Text type='subtitle1'>
                                            {sensor?.equipment.units || <Skeleton width={64} />}
                                        </Text>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Pane>
                        <div className='p-2'>
                            <BarChart
                                title={sensor && { title: sensor.equipment.name }}
                                data={graphData}
                                loading={!sensor}
                            />
                        </div>
                    </Pane>
                </article>
                <ProjectSensorDataTable loading={loading} sensorDataList={tableList} />
            </Tabs>
        </ProjectLayout>
    )
}
