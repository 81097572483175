import { FunctionComponent, ReactNode } from 'react'
import { Auth0Provider } from '@auth0/auth0-react'
import { config } from '@constants'

export const UserAuthRedirectProvider: FunctionComponent<{ children: ReactNode }> = ({ children }) => {
    const onRedirectCallback = (_: unknown) => {
        console.info('Login was registered successfully')
    }

    return (
        <Auth0Provider
            onRedirectCallback={onRedirectCallback}
            domain={config.AUTH0_DOMAIN}
            clientId={config.AUTH0_CLIENT_ID}
            authorizationParams={{
                redirect_uri: window.location.origin,
            }}
            useRefreshTokens={true}
            cacheLocation={'localstorage'}
        >
            {children}
        </Auth0Provider>
    )
}
