import { FunctionComponent } from 'react'
import { ITableField, ITableRowProps, TableRow } from '@components/base/Table'
import { Text } from '@components/base'
import { dateToReadable, SortDirection, sortPrimitives } from '@lib/utils'
import {IDataAnnotation} from "@lib/app/sensor";

export const PROJECT_SENSOR_ANNOTATION_TABLE_FIELDS: ITableField[] = [
    {
        text: 'Id',
        key: 'id',
        sortingFunction: (a: IDataAnnotation, b: IDataAnnotation, direction: SortDirection) =>
            sortPrimitives(a.id, b.id, direction),
        sortByDefault: true,
    },
    {
        text: 'Key',
        key: 'key',
        sortingFunction: (a: IDataAnnotation, b: IDataAnnotation, direction: SortDirection) =>
            sortPrimitives(a.key, b.key, direction),
    },
    {
        text: 'Host',
        key: 'host',
        sortingFunction: (a: IDataAnnotation, b: IDataAnnotation, direction: SortDirection) =>
            sortPrimitives(a.host, b.host, direction),
    },
    {
        text: 'Kind',
        key: 'kind',
        sortingFunction: (a: IDataAnnotation, b: IDataAnnotation, direction: SortDirection) =>
            sortPrimitives(a.kind, b.kind, direction),
    },
    {
        text: 'Signature',
        key: 'signature',
        sortingFunction: (a: IDataAnnotation, b: IDataAnnotation, direction: SortDirection) =>
            sortPrimitives(a.signature, b.signature, direction),
    },
    {
        text: 'Is Satisfied',
        key: 'isSatisfied',
        sortingFunction: (a: IDataAnnotation, b: IDataAnnotation, direction: SortDirection) =>
            sortPrimitives(a.isSatisfied, b.isSatisfied, direction),
    },
    {
        text: 'Timestamp',
        key: 'timestamp',
        sortingFunction: (a: IDataAnnotation, b: IDataAnnotation, direction: SortDirection) =>
            sortPrimitives(a.timestamp, b.timestamp, direction),
    },
]

export const ProjectSensorAnnotationTableRow: FunctionComponent<ITableRowProps<IDataAnnotation>> = (annotation) => {
    const { id, key, host, kind, signature, isSatisfied, timestamp } = annotation?.data ?? ({} as IDataAnnotation)
    const cells = [
        <div className='max-w-[15vw] overflow-auto scrollbar-hidden px-4'>
            <Text type='body1'>{id}</Text>
        </div>,
        <div className='max-w-[20vw] overflow-auto scrollbar-hidden px-4'>
            <Text type='body1'>{key}</Text>
        </div>,
        <div className='max-w-[15vw] overflow-auto scrollbar-hidden px-4'>
            <Text type='body1'>{host}</Text>
        </div>,
        <div className='max-w-[10vw] overflow-auto scrollbar-hidden px-4'>
            <Text type='body1'>{kind}</Text>
        </div>,
        <div className='max-w-[15vw] overflow-auto scrollbar-hidden px-4'>
            <Text type='body1'>{signature}</Text>
        </div>,
        <div className='max-w-[10vw] overflow-auto scrollbar-hidden px-4'>
            <Text type='body1'>{isSatisfied ? 'True' : 'False'}</Text>
        </div>,
        <div className='max-w-[15vw] overflow-auto scrollbar-hidden px-4'>
            <Text type='body1'>{dateToReadable(new Date(timestamp))}</Text>
        </div>,
    ]

    return <TableRow>{cells.map((cell) => cell)}</TableRow>
}
