import { FunctionComponent, useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ProfileLayout, ProjectDetails, ProjectDetailSkeleton } from '@components/app'
import { Button, IconName, Loading } from '@components/base'
import { config } from '@constants'
import { IProjectSites } from '@lib/app/project'
import classes from './OverviewPage.module.scss'
import { UserContext } from '@lib/app/user'

export const OverviewPage: FunctionComponent = () => {
    const [projectsInfo, setProjectsInfo] = useState<IProjectSites>({ sites: [] })
    const { data } = useContext(UserContext)
    const [loading, setLoading] = useState(data.sites.length === 0)
    const [reloading, setReloading] = useState(true)

    useEffect(() => {
        if (data && data.sites) {
            setProjectsInfo({
                ...projectsInfo,
                sites: data.sites,
            })
            setLoading(false)
        } else {
            console.log('No data')
            setReloading(true)
        }
    }, [data])

    const navigate = useNavigate()

    function onAddNewProjectClick() {
        navigate('/add-stream')
    }

    return loading ? (
        <Loading />
    ) : (
        <ProfileLayout title="Overview">
            {!projectsInfo && <ProjectDetailSkeleton />}
            {projectsInfo?.sites && projectsInfo?.sites?.length === 0 && reloading && <ProjectDetailSkeleton />}
            {projectsInfo?.sites && projectsInfo?.sites?.length === 0 && !config.DEMO_MODE && (
                <article className={classes.projectDetails}>
                    <Button size='large' icon={IconName.Plus} onClick={onAddNewProjectClick}>
                        Add New Project
                    </Button>
                </article>
            )}
            {projectsInfo?.sites &&
                projectsInfo?.sites?.map((i, index) => <ProjectDetails key={index} {...i} />)}
        </ProfileLayout>
    )
}
