import { useEffect, useState, useRef, FunctionComponent, ReactNode } from 'react'
import { autoUpdate, computePosition, flip, shift, type ComputePositionConfig, type Side } from '@floating-ui/dom'
import { OutsideAlerter } from './OutsideAlerter.tsx'
import { isArray } from '@lib/utils'

interface IPositionedElement {
    element: HTMLElement | null
    top?: string
    right?: string
    bottom?: string
    left?: string
    rotate?: string
}

interface IPopoverProps {
    children: ReactNode | ReactNode[]
    anchor: HTMLElement | HTMLDivElement | HTMLButtonElement
    placement?: Side
    event?: PopoverEvent
    onOutsideClick?: () => void
}

const popoverEvents = ['click', 'hover', 'focus-blur', 'focus-click'] as const
export type PopoverEvent = (typeof popoverEvents)[number]

export const Popover: FunctionComponent<IPopoverProps> = (props) => {
    const { placement, children, event, onOutsideClick } = props
    const [visible, setVisible] = useState(false)
    const [anchor, _] = useState(props.anchor ?? null)
    const [popoverPosition, setPopoverPosition] = useState<IPositionedElement>({ element: null })
    const popoverRef = useRef<HTMLDivElement>(null)
    const [anchorMouseLeave, setAnchorMouseLeave] = useState(true)
    const [popoverMouseLeave, setPopoverMouseLeave] = useState(true)

    useEffect(() => {
        if (event === 'hover') {
            if (anchorMouseLeave && popoverMouseLeave) {
                hide()
            } else {
                show()
            }
        }
    }, [anchorMouseLeave, popoverMouseLeave, event])

    // Update popover position
    const updatePosition = () => {
        if (!anchor || !popoverRef.current) return

        const options: Partial<ComputePositionConfig> = {
            placement,
            middleware: [flip(), shift()],
            strategy: 'absolute',
        }

        computePosition(anchor, popoverRef.current, options).then(({ x, y }) => {
            setPopoverPosition({
                ...popoverPosition,
                top: `${y}px`,
                left: `${x}px`,
            })
        })
    }

    // Manage events
    useEffect(() => {
        if (anchor && event) {
            removeAllEventListeners()
            manageEventListeners('add', event)
        }
        return () => removeAllEventListeners() // Cleanup on unmount
    }, [anchor, event])

    const manageEventListeners = (methodPrefix: 'add' | 'remove', event: PopoverEvent) => {
        const method = `${methodPrefix}EventListener` as 'addEventListener' | 'removeEventListener'
        if (!anchor || !popoverRef.current) return

        switch (event) {
            case 'click':
                anchor[method]('click', toggle, true)
                /* eslint-disable @typescript-eslint/ban-ts-comment */
                // @ts-ignore
                window[method]('click', onWindowClick, true)
                break
            case 'hover':
                anchor[method]('mouseover', () => setAnchorMouseLeave(false), true)
                anchor[method]('mouseleave', () => setAnchorMouseLeave(true), true)
                popoverRef.current[method]('mouseover', () => setPopoverMouseLeave(false), true)
                popoverRef.current[method]('mouseleave', () => setPopoverMouseLeave(true), true)
                break
            case 'focus-blur':
                anchor[method]('focus', toggle, true)
                anchor[method]('blur', hide, true)
                break
            case 'focus-click':
                anchor[method]('focus', show, true)
                /* eslint-disable @typescript-eslint/ban-ts-comment */
                // @ts-ignore
                window[method]('click', onWindowClick, true)
                break
            default:
                throw new Error(`Event value of '${event}' is not supported.`)
        }

        /* eslint-disable @typescript-eslint/ban-ts-comment */
        // @ts-ignore
        window[method]('keydown', onWindowKeyDown, true)
    }

    const removeAllEventListeners = () => {
        popoverEvents.forEach((event) => manageEventListeners('remove', event))
    }

    // Show or hide popover
    const toggle = () => (visible ? hide() : show())
    const show = () => {
        if (!anchor || !popoverRef.current) return
        popoverRef.current?.style.setProperty('visibility', 'visible')
        popoverRef.current?.style.setProperty('opacity', '1')
        setVisible(true)
        autoUpdate(anchor, popoverRef.current, updatePosition)
    }
    const hide = () => {
        popoverRef.current?.style.setProperty('visibility', 'hidden')
        popoverRef.current?.style.setProperty('opacity', '0')
        setVisible(false)
    }

    // Handle window events
    const onWindowClick = (event: TouchEvent | MouseEvent) => {
        if (!visible || !anchor || !popoverRef.current) return
        if (anchor.contains(event.target as Node)) return

        if (!popoverRef.current.contains(event.target as Node)) {
            hide()
        }
    }

    const onWindowKeyDown = (event: KeyboardEvent) => {
        if (!visible || !anchor || !popoverRef.current) return

        if (event.key === 'Escape') {
            event.preventDefault()
            anchor.focus()
            hide()
        }
    }

    function onOutsideAlertClick(): void {
        hide()
        onOutsideClick && onOutsideClick()
    }

    return (
        <OutsideAlerter onClick={onOutsideAlertClick}>
            <div
                ref={popoverRef}
                className={`relative z-10 bg-[var(--surface-0)] dark:bg-[var(--surface-0-dark)] transition-opacity duration-300`}
            >
                <div className={`clipped-right absolute top-4 right-8`}>
                    {isArray(children as ReactNode[]) ? (
                        <div className='w-full flex flex-col items-center justify-center bg-[var(--surface-0)] dark:bg-[var(--surface-0-dark)] drop-shadow divide-y-[1px] divide-[var(--stroke)] dark:divide-[var(--stroke-dark)]'>
                            {(children as ReactNode[]).map((c) => c)}
                        </div>
                    ) : (
                        <div className='w-full flex flex-col items-center justify-center bg-[var(--surface-0)] dark:bg-[var(--surface-0-dark)] drop-shadow'>
                            {children as ReactNode}
                        </div>
                    )}
                </div>
            </div>
        </OutsideAlerter>
    )
}
