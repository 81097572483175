import { FunctionComponent, ReactNode, useContext, useState } from 'react'
import { Text } from '@components/base/Text'
import paneClasses from '@components/base/Pane/Pane.module.scss'
import classes from './Tabs.module.scss'
import { AppSettingsContext } from '@lib/app/settings'

interface ITabsProps {
    children: ReactNode[]
    tabHeaders: string[]
    onTabClick?: (index: number) => void
}

export const Tabs: FunctionComponent<ITabsProps> = (props) => {
    const { children, tabHeaders, onTabClick = (_) => {} } = props
    const [activeTabIndex, setActiveTabIndex] = useState<number>(0)
    const isDark = useContext(AppSettingsContext).data.theme === 'dark'

    function onTabHeaderClick(index: number): void {
        onTabClick(index)
        setActiveTabIndex(index)
    }

    return (
        <div className={`flex flex-col space-y-8 clipped ${isDark ? paneClasses['pane-dark'] : ''}`}>
            <div className='flex flex-row items-center justify-start space-x-4 border-b-[1px] border-[var(--stroke)] dark:border-[var(--stroke-dark)]'>
                {tabHeaders.map((h, idx) => (
                    <div
                        className={`${classes.hoverable} ${isDark ? classes.hoverableDark : ''} cursor-pointer hover:border-b-[3px] hover:border-[var(--text-brand-primary)] dark:hover:border-[var(--text-brand-primary-dark)] ${idx === activeTabIndex ? 'border-b-[3px] border-[var(--text-brand-primary)] dark:border-[var(--text-brand-primary-dark)]' : ''}`}
                        onClick={() => onTabHeaderClick(idx)}
                    >
                        <div className={`w-full h-full px-8 py-6 ${idx === activeTabIndex ? 'translate-y-[2px]' : ''}`}>
                            <Text type='subtitle2' color={idx === activeTabIndex ? 'text-primary' : 'text-secondary'}>
                                {h}
                            </Text>
                        </div>
                    </div>
                ))}
            </div>
            {children[activeTabIndex]}
        </div>
    )
}
