import { BaseApiService } from '@lib/core/api'
import { IDataReport, IHederaContext, IHederaGuardianRegistration, IHederaReport } from '@lib/app/hedera'
import { IVerifiableCredential } from '@lib/app/identity'

export class HederaApiService extends BaseApiService {
    constructor(baseUrl: string) {
        super(baseUrl)
    }

    async getHederaContext(siteId: string): Promise<IHederaContext> {
        const url = `api/guardian/${siteId}/hedera`
        return this.get(url)
    }

    async getVcData(vc: IVerifiableCredential): Promise<IDataReport[]> {
        const url = `api/guardian/data`
        return this.post(url, vc as object)
    }

    async registerHederaGuardian(siteId: string, registration: IHederaGuardianRegistration): Promise<unknown> {
        const url = `api/guardian/${siteId}/hedera/register`
        return this.post(url, registration as object)
    }

    async submitHederaReport(siteId: string, report?: IHederaReport): Promise<void> {
        const url = `api/guardian/${siteId}/hedera/report`
        return this.post(url, report as object)
    }

    async disconnectHederaGuardian(siteId: string): Promise<void> {
        const url = `api/guardian/${siteId}/hedera/disconnect`
        return this.post(url, {})
    }
}
