import { useNavigate, useParams } from 'react-router-dom'
import { formula } from '@assets'
import { Button, Pane, Text, TextInput } from '@components/base'
import { ProjectLayout } from '@components/app'
import { buildProjectRoute, ProjectAnalyticsRoute, ProjectRoute } from '@lib/app/routing'

const parametersData = [
    {
        title: 'E SSR P8',
        text: 'Total SSR P8 GHG emissions from fossil fuel combustion, other than natural gas, for the project reporting period',
        input: false,
    },
    {
        title: 'E SSR P8',
        text: 'Total SSR P8 GHG emissions from fossil fuel combustion, other than natural gas, for the project reporting period',
        input: true,
    },
    {
        title: 'E SSR P8',
        text: 'Total SSR P8 GHG emissions from fossil fuel combustion, other than natural gas, for the project reporting period',
        input: true,
    },
    {
        title: 'E SSR P8',
        text: 'Total SSR P8 GHG emissions from fossil fuel combustion',
        input: true,
    },
    {
        title: 'E SSR P8',
        text: 'Total SSR P8 GHG emissions from fossil fuel combustion, other than natural gas, for the project reporting period',
        input: true,
    },
]

export const ProjectAnalyticsParameterCustomizationPage = () => {
    const { slug } = useParams()
    const url = buildProjectRoute(ProjectRoute.Analytics, ProjectAnalyticsRoute.Setup).replace(':slug', slug ?? '')
    const navigate = useNavigate()

    const onCancelClick = () => {
        navigate(url)
    }

    return (
        <ProjectLayout title='Customize Parameters'>
            <Pane>
                <div className='p-4'>
                    <figure className='bg-surface-0 p-8 shadow-md'>
                        <img src={formula} alt='Formula' />
                    </figure>
                </div>
                <div className='px-6 py-4 flex flex-col space-y-4'>
                    <div className='divide-y-[1px] divide-stroke dark:divide-stroke-dark'>
                        {parametersData.map((item, index) => (
                            <div className='py-6 flex flex-row items-center justify-between' key={index}>
                                <div className='flex flex-col space-y-4'>
                                    <Text type='body1' color='text-secondary'>
                                        {item.title}
                                    </Text>
                                    <Text type='body1'>{item.text}</Text>
                                </div>
                                {item.input ? <TextInput id='analytics-parameter' label='Value' /> : ''}
                            </div>
                        ))}
                    </div>
                    <div className='flex flex-row items-center justify-end space-x-4'>
                        <Button variant='text' size='medium' onClick={onCancelClick}>
                            Cancel
                        </Button>
                        <Button size='medium' onClick={() => {}}>
                            Save
                        </Button>
                    </div>
                </div>
            </Pane>
        </ProjectLayout>
    )
}
