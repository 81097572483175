import { FunctionComponent } from 'react'
import { ITableField, Table } from '@components/base/Table'
import { IProjectDocument } from '@lib/app/project'
import { ProjectDocumentationTableRow } from './ProjectDocumentationTableRow.tsx'
import { SortDirection, sortPrimitives } from '@lib/utils'

interface IProjectDocumentationTableProps {
    loading: boolean
    documents: IProjectDocument[]
    downloadFn: (document: IProjectDocument) => Promise<void>
    deleteFn: (document: IProjectDocument) => Promise<void>
    hideEntity?: boolean
}

const DOCUMENTATION_FILE_TABLE_FIELDS: ITableField[] = [
    {
        text: 'Name',
        key: 'name',
        sortingFunction: (a: IProjectDocument, b: IProjectDocument, direction: SortDirection) =>
            sortPrimitives(a.file.name, b.file.name, direction),
        sortByDefault: true,
    },
    {
        text: 'Entity',
        key: 'entity',
        sortingFunction: (a: IProjectDocument, b: IProjectDocument, direction: SortDirection) =>
            sortPrimitives(a.file.owner, b.file.owner, direction),
    },
    {
        text: 'Type',
        key: 'type',
        sortingFunction: (a: IProjectDocument, b: IProjectDocument, direction: SortDirection) =>
            sortPrimitives(a.file.metadata?.type, b.file.metadata?.type, direction),
    },
    {
        text: 'Size',
        key: 'size',
        sortingFunction: (a: IProjectDocument, b: IProjectDocument, direction: SortDirection) =>
            sortPrimitives(a.file.metadata?.size, b.file.metadata?.size, direction),
    },
    {
        text: 'Last Modified',
        key: 'uploadedOn',
        sortingFunction: (a: IProjectDocument, b: IProjectDocument, direction: SortDirection) =>
            sortPrimitives(a.file.lastModified, b.file.lastModified, direction),
    },
]

export const ProjectDocumentationTable: FunctionComponent<IProjectDocumentationTableProps> = (props) => {
    const { loading, documents, hideEntity } = props

    const disabledColumns = {
        ...(hideEntity && { entity: 1 }),
    }

    function getFileTableFields(): ITableField[] {
        return DOCUMENTATION_FILE_TABLE_FIELDS.filter((field) => !(field.key in disabledColumns))
    }

    return (
        <Table
            fields={getFileTableFields()}
            data={documents}
            RowTemplate={({ data }) => ProjectDocumentationTableRow({ data, hideEntity })}
            loading={loading}
        />
    )
}
