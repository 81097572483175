import { FunctionComponent, ReactNode, useContext } from 'react'
import { useNavigate } from 'react-router-dom'
import { Logo } from '@components/base/Logo'
import { AppRoute, buildRoute } from '@lib/app/routing'
import { SidebarAcccordion } from './SidebarAccordion.tsx'
import { AppSettingsContext } from '@lib/app/settings'

interface ISidebarProps {
    children: ReactNode
}

export const Sidebar: FunctionComponent<ISidebarProps> = (props) => {
    const { children } = props
    const navigate = useNavigate()
    const theme = useContext(AppSettingsContext).data.theme
    const isDark = theme === 'dark'

    return (
        <aside
            className={`sticky top-0 left-0 bottom-0 w-auto z-[1] inline-block min-h-[100vh] backdrop-blur ${isDark ? 'bg-gradient-sidebar-dark' : 'bg-gradient-sidebar'}`}
        >
            <div className='flex flex-col'>
                <div className='px-8 pt-4 pb-8 cursor-pointer'>
                    <a
                        onClick={() => {
                            navigate(buildRoute(AppRoute.Overview))
                        }}
                    >
                        <Logo />
                    </a>
                </div>
                <SidebarAcccordion></SidebarAcccordion>
            </div>
            {children}
        </aside>
    )
}
