import { decimalToHex } from './number'

export const COLOR_PALETTE: ColorPalette = {
    gradient: {
        layout: {
            0: {
                DEFAULT: '#f4ecf5',
                dark: '#1b262d',
            },
            1: {
                DEFAULT: '#e7f7f4',
                dark: '#221227',
            },
        },
        sidebar: {
            0: {
                DEFAULT: '#e8e5e8',
                dark: '#252632',
            },
            1: {
                DEFAULT: '#f4f6f8',
                dark: '#191321',
            },
        },
    },
    stroke: {
        DEFAULT: '#d9e1e8',
        dark: '#9ba3ae',
        light: {
            DEFAULT: '#ebeef0',
            dark: '#6f6c82',
        },
    },
    surface: {
        0: {
            DEFAULT: '#ffffff',
            dark: '#191321',
        },
        1: {
            DEFAULT: '#f4f6f8',
            dark: '#6f6c82',
        },
        brand: {
            primary: {
                DEFAULT: '#ff00ab',
                dark: '#0dce95',
            },
            secondary: {
                DEFAULT: '#0dce95',
                dark: '#ff00ab',
            },
        },
        danger: {
            DEFAULT: '#f44336',
            dark: '#ef5350',
        },
        info: {
            DEFAULT: '#00bcd4',
            dark: '#26c6da',
        },
        invert: {
            DEFAULT: '#191321',
            dark: '#ffffff',
        },
        success: {
            DEFAULT: '#0dce95',
            dark: '#0dce95',
        },
        transparent: {
            DEFAULT: '#f4f6f8/50',
            dark: '#19132126',
        },
        warning: {
            DEFAULT: '#f57c00',
            dark: '#ffa726',
        },
    },
    text: {
        brand: {
            primary: {
                DEFAULT: '#ff00ab',
                dark: '#0dce95',
                hover: {
                    DEFAULT: '#e20095',
                    dark: '#12ab7e',
                },
            },
            secondary: {
                DEFAULT: '#0dce95',
                dark: '#ff00ab',
            },
        },
        danger: {
            DEFAULT: '#f44336',
            dark: '#f44336',
        },
        info: {
            DEFAULT: '#4dd0e1',
            dark: '#4dd0e1',
        },
        invert: {
            DEFAULT: '#ffffff',
            dark: '#151221',
        },
        primary: {
            DEFAULT: '#151221',
            dark: '#ffffff',
        },
        secondary: {
            DEFAULT: '#6f6c82',
            dark: '#d9e1e8',
        },
        success: {
            DEFAULT: '#0dce95',
            dark: '#00bf89',
        },
        warning: {
            DEFAULT: '#ff9800',
            dark: '#ff9800',
        },
    },
}

const themes = ['light', 'dark'] as const
export type Theme = (typeof themes)[number]

export type Style = Record<string | number, string & {}>

export type ColorPalette = Record<string | number, string | unknown>

export function isDarkPreferred(): boolean {
    return window.matchMedia('(prefers-color-scheme: dark)').matches
}

export function calculateChartYAxisDomain([dataMin, dataMax]: [number, number]): [number, number] {
    const scaleFactor = 0.1
    if (dataMin <= 0 && dataMax <= 0) {
        return [dataMin * (1 + scaleFactor), 0]
    } else if (dataMin > 0 && dataMax > 0) {
        return [0, dataMax * (1 + scaleFactor)]
    } else {
        return [dataMin * (1 + scaleFactor), dataMax * (1 + scaleFactor)]
    }
}

export function checkHtmlRootForDarkMode(isDark: boolean): void {
    const root = document.documentElement
    if (isDark && !root.classList.contains('dark')) {
        root.classList.add('dark')
    } else if (!isDark && root.classList.contains('dark')) {
        root.classList.remove('dark')
    }
}

export function toggleHtmlRootDarkMode(): void {
    const root = document.documentElement
    if (root.classList.contains('dark')) {
        root.classList.remove('dark')
    } else {
        root.classList.add('dark')
    }
}

export function getColorHex(color: string): string | undefined {
    const [tokenString, opacity] = color.split('/')

    const opacityString = Number.isNaN(Number(opacity)) ? '' : decimalToHex(Number(opacity))

    const tokens = tokenString.split('-')
    switch (tokens.length) {
        case 1:
            /* eslint-disable @typescript-eslint/ban-ts-comment */
            // @ts-ignore
            return getColorFromPalette(COLOR_PALETTE[tokens[0]]) + opacityString
        case 2:
            /* eslint-disable @typescript-eslint/ban-ts-comment */
            // @ts-ignore
            return getColorFromPalette(COLOR_PALETTE[tokens[0]][tokens[1]]) + opacityString
        case 3:
            return (
                /* eslint-disable @typescript-eslint/ban-ts-comment */
                // @ts-ignore
                getColorFromPalette(COLOR_PALETTE[tokens[0]][tokens[1]][tokens[2]]) + opacityString
            )
        default:
            return undefined
    }
}

function getColorFromPalette(value: string | ColorPalette): string | undefined {
    if (typeof value === 'string') {
        return value as string
    } else if (typeof value === 'object' && typeof value?.DEFAULT === 'string') {
        return value.DEFAULT as string
    } else {
        return undefined
    }
}
