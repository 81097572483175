//svg
import logo from './svg/logo.svg'
import logoDark from './svg/logo-dark.svg'
import favicon from './svg/favicon.svg'
import signInBtnIcon from './svg/sign-in-btn-icon.svg'
import projectIcon from './svg/project-icon.svg'
import allProjectsIcon from './svg/all-projects-icon.svg'
import arrowIcon from './svg/arrow-icon.svg'
import dashboardIcon from './svg/dashboard-icon.svg'
import activityIcon from './svg/activity-icon.svg'
import userIcon from './svg/user-icon.svg'
import usersIcon from './svg/users-icon.svg'
import analyticsIcon from './svg/analytics-icon.svg'
import documentationIcon from './svg/documentation-icon.svg'
import dataSourcesIcon from './svg/data-sources-icon.svg'
import notificationsIcon from './svg/notifications-icon.svg'
import searchIcon from './svg/search-icon.svg'
import messageIcon from './svg/message-icon.svg'
import actionsIcon from './svg/actions-icon.svg'
import plusIcon from './svg/plus-icon.svg'
import chart from './svg/chart.svg'
import chartDark from './svg/chart-dark.svg'
import sustainability from './svg/sustainability.svg'
import sustainabilityDark from './svg/sustainability-dark.svg'
import clockIcon from './svg/clock-icon.svg'
import threeDots from './svg/three-dots.svg'
import arrowLeft from './svg/arrow-left.svg'
import cargoAverage from './svg/cargo-average-load.svg'
import cargoCurrent from './svg/cargo-icon-current.svg'
import cargoCondidence from './svg/cargo-confidence.svg'
import fileIconBig from './svg/file-icon-big.svg'
import fileIconSmall from './svg/file-icon-small.svg'
import infoIcon from './svg/info-icon.svg'
import arrowRight from './svg/arrow-right.svg'
import uploadIcon from './svg/upload-icon.svg'
import smallArrowUp from './svg/small-arrow-up.svg'
import smallArrowDown from './svg/small-arrow-down.svg'
import sortIconDefault from './svg/sort-icon-default.svg'
import exportIcon from './svg/export-icon.svg'
import exportIconBlack from './svg/export-icon-black.svg'
import settingsIcon from './svg/settings-icon.svg'
import hamburgerIcon from './svg/hamburger-icon.svg'
import arrowRightIcon from './svg/arrow-right-icon.svg'
import eyeIconOff from './svg/eye-icon-off.svg'
import eyeIconOn from './svg/eye-icon-on.svg'
import greyArrowLeft from './svg/grey-arrow-left.svg'
import greyArrowRight from './svg/grey-arrow-right.svg'
import pinIcon from './svg/pin-icon.svg'
import triangle from './svg/triangle.svg'
import deleteTrash from './svg/delete-trash.svg'
import guardianIcon from './svg/guardian-icon.svg'
import sunIcon from './svg/sun-icon.svg'
import moonIcon from './svg/moon-icon.svg'

//png
import signIn from './images/sign-in.png'
import signInDark from './images/sign-in-dark.png'
import iota from './images/iota.png'
import copiulemu from './images/copiulemu.png'
import projectImage from './images/project-image.png'
import car from './images/car.png'
import flowmeter from './images/flowmeter.png'
import statusConnected from './images/connected.png'
import statusDisconnected from './images/disconnected.png'
import formula from './images/formula.png'
import V1 from './images/V1_Default_0deg.png'
import V2 from './images/V2_Default_90deg.png'
import V3 from './images/V3_Default_180deg.png'
import V4 from './images/V4_Default_270deg.png'
import M1Back from './images/M1_back.png'
import M1Front from './images/M1_front.png'
import N1Back from './images/N1_back.png'
import N1Front from './images/N1_front.png'
import F1Back from './images/F1_back.png'
import F1Front from './images/F1_front.png'
import Y1Back from './images/Y1_back.png'
import Y1Front from './images/Y1_front.png'
import greenWaste from './images/green-waste.jpg'
import grapeHarvest from './images/grapeHarvestWaste.jpg'
import liquidWaste from './images/liquidWaste.jpg'

// Molina assets TEMP
// 0 Degree
import Molina0Default from './images/Molina/3d/0/V1_Default.jpg'
import Molina0Overlay1 from './images/Molina/3d/0/V1_1_Overlay.jpg'
import Molina0Overlay2 from './images/Molina/3d/0/V1_2_Overlay.jpg'
import Molina0Overlay3 from './images/Molina/3d/0/V1_3_Overlay.jpg'
import Molina0Zoom1 from './images/Molina/3d/0/zoom/Z1_1_Overlay.jpg'
import Molina0Zoom2 from './images/Molina/3d/0/zoom/Z1_2_Overlay.jpg'
import Molina0Zoom3 from './images/Molina/3d/0/zoom/Z1_3_Overlay.jpg'

// 90 Degree
import Molina90Default from './images/Molina/3d/90/V2_Default.jpg'
import Molina90Overlay1 from './images/Molina/3d/90/V2_1_Overlay.jpg'
import Molina90Overlay2 from './images/Molina/3d/90/V2_2_Overlay.jpg'
import Molina90Overlay3 from './images/Molina/3d/90/V2_3_Overlay.jpg'
import Molina90Overlay4 from './images/Molina/3d/90/V2_4_Overlay.jpg'
import Molina90Zoom1 from './images/Molina/3d/90/zoom/Z2_1_Overlay.jpg'
import Molina90Zoom2 from './images/Molina/3d/90/zoom/Z2_2_Overlay.jpg'

// 180 Degree
import Molina180Default from './images/Molina/3d/180/V3_Default.jpg'
import Molina180Overlay1 from './images/Molina/3d/180/V3_1_Overlay.jpg'
import Molina180Overlay2 from './images/Molina/3d/180/V3_2_Overlay.jpg'
import Molina180Overlay3 from './images/Molina/3d/180/V3_3_Overlay.jpg'
import Molina180Overlay4 from './images/Molina/3d/180/V3_4_Overlay.jpg'

export const molina = {
    // 0 Degree
    0: {
        default: Molina0Default,
        overlays: [Molina0Overlay1, Molina0Overlay2, Molina0Overlay3],
        zooms: [Molina0Zoom1, Molina0Zoom2, Molina0Zoom3],
    },
    // 90 Degree
    90: {
        default: Molina90Default,
        overlays: [Molina90Overlay1, Molina90Overlay2, Molina90Overlay3, Molina90Overlay4],
        zooms: [Molina90Zoom1, Molina90Zoom2],
    },
    // 180 Degree
    180: {
        default: Molina180Default,
        overlays: [Molina180Overlay1, Molina180Overlay2, Molina180Overlay3, Molina180Overlay4],
    },
}

export {
    logo,
    logoDark,
    favicon,
    signInBtnIcon,
    chart,
    chartDark,
    sustainability,
    sustainabilityDark,
    clockIcon,
    signIn,
    signInDark,
    iota,
    projectIcon,
    allProjectsIcon,
    arrowIcon,
    dashboardIcon,
    activityIcon,
    userIcon,
    usersIcon,
    analyticsIcon,
    documentationIcon,
    dataSourcesIcon,
    notificationsIcon,
    searchIcon,
    copiulemu,
    projectImage,
    messageIcon,
    actionsIcon,
    threeDots,
    plusIcon,
    arrowLeft,
    cargoAverage,
    cargoCurrent,
    cargoCondidence,
    car,
    flowmeter,
    statusConnected,
    statusDisconnected,
    fileIconBig,
    fileIconSmall,
    infoIcon,
    arrowRight,
    uploadIcon,
    smallArrowUp,
    smallArrowDown,
    sortIconDefault,
    exportIcon,
    exportIconBlack,
    formula,
    settingsIcon,
    hamburgerIcon,
    arrowRightIcon,
    eyeIconOff,
    eyeIconOn,
    V1,
    V2,
    V3,
    V4,
    greyArrowLeft,
    greyArrowRight,
    M1Back,
    M1Front,
    N1Back,
    N1Front,
    F1Back,
    F1Front,
    Y1Back,
    Y1Front,
    pinIcon,
    triangle,
    greenWaste,
    grapeHarvest,
    liquidWaste,
    deleteTrash,
    guardianIcon,
    sunIcon,
    moonIcon,
}
