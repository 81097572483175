import { FunctionComponent, ReactNode, useState } from 'react'
import { AppSettingsContext, DEFAULT_APP_SETTINGS, IAppSettings } from '@lib/app/settings'
import { IContext } from '@lib/core/context'
import { checkHtmlRootForDarkMode, StorageManager } from '@lib/utils'

interface IAppProviderProps {
    children: ReactNode
}

export const AppProvider: FunctionComponent<IAppProviderProps> = (props) => {
    const { children } = props

    if (!StorageManager.exists('appSettings')) {
        StorageManager.save('appSettings', DEFAULT_APP_SETTINGS)
    }

    const persistedAppSettings = StorageManager.load('appSettings') as IAppSettings
    const [appSettings, setAppSettings] = useState<IAppSettings>(persistedAppSettings)
    const appSettingsContext: IContext<IAppSettings> = {
        data: appSettings,
        setData: onAppSettingsUpdate,
    }

    checkHtmlRootForDarkMode(appSettings.theme === 'dark')

    function onAppSettingsUpdate(payload: Partial<IAppSettings>): void {
        const _appSettings = {
            ...appSettings,
            ...payload,
        }
        setAppSettings(_appSettings)
        StorageManager.save('appSettings', _appSettings)
    }

    return <AppSettingsContext.Provider value={appSettingsContext}>{children}</AppSettingsContext.Provider>
}
