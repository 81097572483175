import { FunctionComponent, ReactNode, useContext } from 'react'
import { OutsideAlerter } from '@components/base/Popover'
import classes from './Popup.module.scss'
import { AppSettingsContext } from '@lib/app/settings'

interface IPopupProps {
    children: ReactNode
    onClose: () => void
}

export const Popup: FunctionComponent<IPopupProps> = (props) => {
    const { children, onClose } = props
    const isDark = useContext(AppSettingsContext).data.theme === 'dark'
    return (
        <div className={classes.popupOverlay}>
            <div className={classes.popup}>
                <OutsideAlerter onClick={onClose}>
                    <div className={`${classes.popupContent} ${isDark ? classes.popupContentDark : ''}`}>
                        {children}
                    </div>
                </OutsideAlerter>
            </div>
        </div>
    )
}
