import { FormEvent, useContext, useEffect, useMemo, useState } from 'react'
import { PropagateLoader } from 'react-spinners'
import { useAuth0 } from '@auth0/auth0-react'
import { loadStripe } from '@stripe/stripe-js'
import { Elements } from '@stripe/react-stripe-js'
import { Skeleton } from '@components/base/Skeleton'

import {Button, Pane, Text, PasswordInput, TextInput, Tabs, Copyable} from '@components/base'
import { CheckoutForm, IdentityTable, ProfileLayout, SubscriptionInfo } from '@components/app'
import { config } from '@constants'
import { IProjectSites } from '@lib/app/project'
import { IUserData, IUserSubscriptionInfo, UserContext } from '@lib/app/user'
import { createApiService } from '@lib/core/api'

import classes from './ProfilePage.module.scss'
import { FEATURES } from '@features'

export const ProfilePage = () => {
    const api = createApiService(config.API_BASE_URL)
    const {user, isAuthenticated, getIdTokenClaims, isLoading} = useAuth0()
    const stripePromise = loadStripe(config.STRIPE_API_KEY)

    const userData = useContext(UserContext).data

    const [profile, setProfile] = useState<IUserData>()
    const [subscription, setSubscription] = useState<{ [x: string]: IUserSubscriptionInfo } | null>(null)

    const [hederaProfile, setHederaProfile] = useState<any | null>(null)
    const [hederaProfileLoading, setHederaProfileLoading] = useState(true)
    const [hederaDidLinking, setHederaDidLinking] = useState<boolean>(false)

    const [customerId, setCustomerId] = useState<string | null>(null)
    const [sites, setSites] = useState<IProjectSites | null>(null)

    const isProfileLoading = useMemo(() => {
        return profile === undefined
    }, [profile])

    useEffect(() => {
        // const fetchSites = async (): Promise<IProjectSites> => {
        //     return api.context
        //         .getProjects()
        //         .then((response) => {
        //             setSites(response)
        //             return response
        //         })
        //         .catch((err) => {
        //             console.error(err.message)
        //             throw err
        //         })
        // }

        const fetchSubscriptions = async (customerId: string, sites: IProjectSites) => {
            api.user.getSubscription(customerId).then((response) => {
                response.map((sub) => {
                    sites.sites.map((site) => {
                        const projectId = site.projectId
                        if (sub.metadata['project_id'] === projectId) {
                            const newSubscription = subscription ? {...subscription} : {}
                            newSubscription[projectId] = sub
                            setSubscription(newSubscription)
                        }
                    })
                })
            })
        }

        if (!isLoading) {
            if (FEATURES.project.guardian.enabled) {
                // dont await
                getHederaProfile()
            }

            api.user
                .profile()
                .then(setProfile)
                .then(() => {
                    if (userData && user && isAuthenticated) {
                        getIdTokenClaims().then((token) => {
                            if (token) {
                                setCustomerId(token.stripeCustomerId)
                                if (!config.DEMO_MODE) {
                                    fetchSubscriptions(token.stripeCustomerId, {sites: userData.sites}).catch(
                                        (err) => {
                                            throw err
                                        }
                                    )
                                }
                            }
                        })
                        setSites({sites: userData.sites})
                    }
                })
                .catch((err) => {
                    console.error(err.message)
                })
        }
    }, [isLoading])

    const getHederaProfile = async () => {
        await api.user
            .hederaProfile()
            .then((profile) => {
                console.log(profile)
                setHederaProfile(profile)
            })
            .catch((error) => {
                console.error(error.message)
            })
            .finally(() => {
                setHederaProfileLoading(false)
            })
    }

    async function hederaLink(event: FormEvent<HTMLFormElement>) {
        if (hederaDidLinking) {
            return
        } else {
            setHederaDidLinking(true)
            event.preventDefault()

            const form = event.target as typeof event.target & {
                username: { value: string }
                password: { value: string }
            }

            await api.user
                .hederaLink(form.username.value, form.password.value)
                .then((profile) => {
                    setHederaProfile(profile)
                })
                .catch((error) => {
                    console.log(error)
                })
        }
    }

    const tabs = FEATURES.project.guardian.enabled
        ? !config.DEMO_MODE
            ? ['Profile', 'Identity', 'Hedera', 'Subscription']
            : ['Profile', 'Identity', 'Hedera']
        : !config.DEMO_MODE
            ? ['Profile', 'Identity', 'Subscription']
            : ['Profile', 'Identity']

    return (
        <ProfileLayout title='Profile'>
            <div className={classes.profile}>
                <div className='flex flex-col space-y-8'>
                    <Tabs tabHeaders={tabs}>
                        <Pane>
                            <div>
                                <Text type='body2' color='text-secondary'>
                                    Username
                                </Text>
                                {profile?.user?.username ? (
                                    <Copyable value={profile?.user?.username ?? ''}/>
                                ) : (
                                    <Skeleton/>
                                )}
                            </div>
                            <div>
                                <Text type='body2' color='text-secondary'>
                                    User ID
                                </Text>
                                {profile?.user?.keycloak_id ? (
                                    <Copyable value={profile?.user?.keycloak_id ?? ''}/>
                                ) : (
                                    <Skeleton/>
                                )}
                            </div>
                            <div>
                                <Text type='body2' color='grey'>
                                    DID Document ID
                                </Text>
                                {profile?.doc_id ? (
                                    <Copyable value={profile?.doc_id ?? ''}/>
                                ) : (
                                    <Skeleton/>
                                )}
                            </div>
                        </Pane>
                        <Pane>
                            <div>
                                <Text type='header3'>DID Document ID</Text>
                                <p onClick={() => navigator.clipboard.writeText(profile!.doc_id)}>
                                    {profile?.doc_id || <Skeleton/>}
                                </p>
                            </div>
                            <br/>
                            <Text type='header4'>Identity Methods</Text>
                            <IdentityTable
                                loading={isProfileLoading}
                                methods={profile?.methods ?? []}
                                hiddenColumns={['id']}
                            />
                        </Pane>
                        {FEATURES.project.guardian.enabled && (
                            <Pane>
                                <section className={classes.projectInfo}>
                                    <h2 className={classes.title}>Hedera Details</h2>
                                    <div className='flex flex-col space-y-4'>
                                        {hederaProfile ? (
                                            <>
                                                <div>
                                                    <Text type='body2' color='grey'>
                                                        Username
                                                    </Text>
                                                    <p
                                                        onClick={() =>
                                                            navigator.clipboard.writeText(hederaProfile!.username)
                                                        }
                                                    >
                                                        {hederaProfile?.username || <Skeleton/>}
                                                    </p>
                                                </div>
                                                <div>
                                                    <Text type='body2' color='grey'>
                                                        Account ID:
                                                    </Text>
                                                    <p
                                                        onClick={() =>
                                                            navigator.clipboard.writeText(
                                                                hederaProfile!.hederaAccountId
                                                            )
                                                        }
                                                    >
                                                        {hederaProfile?.hederaAccountId || <Skeleton/>}
                                                    </p>
                                                </div>
                                                <div>
                                                    <Text type='body2' color='grey'>
                                                        DID:
                                                    </Text>
                                                    <p
                                                        onClick={() =>
                                                            navigator.clipboard.writeText(hederaProfile!.did)
                                                        }
                                                    >
                                                        {hederaProfile?.did || <Skeleton/>}
                                                    </p>
                                                </div>
                                            </>
                                        ) : (
                                            <Pane>
                                                {hederaProfileLoading ? (
                                                    <>
                                                        <Skeleton/>
                                                    </>
                                                ) : (
                                                    <form onSubmit={hederaLink} className='flex flex-col space-y-4'>
                                                        <TextInput id='username' label='Username' required autofocus/>
                                                        <PasswordInput id='password' label='Password' required/>
                                                        <div className='pt-4'>
                                                            <Button type='submit' width='full' onClick={() => {
                                                            }}>
                                                                {hederaDidLinking ? 'Linking...' : 'Link DID'}
                                                            </Button>
                                                        </div>
                                                    </form>
                                                )}
                                            </Pane>
                                        )}
                                    </div>
                                </section>
                            </Pane>
                        )}
                        {!config.DEMO_MODE && (
                            <section className='w-full'>
                                <Text type='header3'>Subscription Details</Text>
                                {sites &&
                                    sites.sites.map((site) => {
                                        const element =
                                            customerId && subscription ? (
                                                Object.keys(subscription).length !== 0 &&
                                                subscription[site.projectId] ? (
                                                    <SubscriptionInfo
                                                        customerId={customerId}
                                                        site={site}
                                                        subscription={subscription[site.projectId]}
                                                    />
                                                ) : (
                                                    <Elements stripe={stripePromise}>
                                                        <CheckoutForm customerId={customerId} site={site}/>
                                                    </Elements>
                                                )
                                            ) : (
                                                <div className='center' style={{margin: 'auto 0'}}>
                                                    <PropagateLoader color='#36d7b7' loading={true}/>
                                                </div>
                                            )
                                        const containerClass = customerId ? classes.projectInfo : classes.loadingBar
                                        return <div className={containerClass}>{element}</div>
                                    })}
                                {!sites && <Text type='body1'>No Sites Available</Text>}
                            </section>
                        )}
                    </Tabs>
                </div>
            </div>
        </ProfileLayout>
    )
}
