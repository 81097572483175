import {FunctionComponent, ReactNode, useContext, useEffect, useState} from 'react'
import { useParams } from 'react-router-dom'
import { IProjectContextData, ProjectContext } from '@lib/app/project'
import { IContext } from '@lib/core/context'
import { UserContext } from '@lib/app/user'
import { StorageManager } from '@lib/utils'
import { IAnalyticsConfiguration } from '@lib/app/analytics'

interface IProjectProviderProps {
    children: ReactNode
}

let endDate: Date = new Date()
let startDate: Date = new Date(new Date().setDate(endDate.getDate() - 30))

let selectedProjectId: string = ''

export const ProjectProvider: FunctionComponent<IProjectProviderProps> = (props) => {
    const { children } = props
    const { slug } = useParams()
    if (slug !== selectedProjectId) {
        selectedProjectId = slug ?? ''
        resetDateSelection()
    }

    const { data } = useContext(UserContext)
    console.log("ProjectProvider ", data)
    console.log("slug: ", slug)
    data.sites.forEach((site) => console.log(site.projectId))
    const [selectedProject, setSelectedProject] = useState(data.sites.find((site) => site.projectId === slug))
    console.log("Selected project ", selectedProject)

    const [startDateState, setStartDate] = useState(startDate)
    const [endDateState, setEndDate] = useState(endDate)

    useEffect(() => {
        setSelectedProject(data.sites.find((site) => site.projectId === slug))
    }, [data.sites, slug]);

    //if (!selectedProject) {
    //    return <></>
    //}

    const projectSettings = StorageManager.load<Record<string, IAnalyticsConfiguration>>('projectSettings') ?? {}
    let analyticsConfiguration = projectSettings[selectedProjectId]

    if (selectedProject) {
        /**
         * NOTE: If there is not an existing analytics config for the project BUT there are profiles,
         * we want to enable every analytics calculation by default.
         */
        if (!analyticsConfiguration) {
            if (selectedProject.profiles && selectedProject.profiles.length > 0) {
                const profile = selectedProject.profiles[0]
                analyticsConfiguration = {
                    calculations: Object.fromEntries(profile.calculations.map((calc) => [calc.id, true])),
                }

                StorageManager.save('projectSettings', {
                    ...projectSettings,
                    [selectedProjectId]: {
                        ...analyticsConfiguration,
                    },
                })
            }
        }

        for (const key in analyticsConfiguration.calculations) {
            if (key.length == 0 || !selectedProject.profiles?.[0]?.calculations.some((calc) => calc.id === key)) {
                delete analyticsConfiguration.calculations[key]
            }
        }

        selectedProject.profiles?.[0]?.calculations.forEach((calc) => {
            if (!analyticsConfiguration.calculations[calc.id]) {
                analyticsConfiguration.calculations[calc.id] = true
            }
        })
    }

    const projectContext: IContext<IProjectContextData> = {
        data: {
            details: selectedProject,
            dateSelection: {
                startDate: startDateState,
                endDate: endDateState,
            },
            ...(analyticsConfiguration && { analyticsConfiguration }),
        },
        setData: updateProjectContext,
    }

    function resetDateSelection(): void {
        endDate = new Date()
        startDate = new Date(new Date().setDate(endDate.getDate() - 30))
    }

    function updateProjectContext(payload: Partial<IProjectContextData>): void {
        if (payload.dateSelection) {
            startDate = payload.dateSelection.startDate
            setStartDate(startDate)
            endDate = payload.dateSelection.endDate
            setEndDate(endDate)

            projectContext.data.dateSelection = {
                ...projectContext.data.dateSelection,
                ...payload.dateSelection,
            }
        }

        if (payload.analyticsConfiguration && projectContext.data.details) {
            StorageManager.save('projectSettings', {
                [projectContext.data.details.projectId]: payload.analyticsConfiguration,
            })

            projectContext.data.analyticsConfiguration = {
                ...projectContext.data.analyticsConfiguration,
                ...payload.analyticsConfiguration,
            }
        }
    }

    return <ProjectContext.Provider value={projectContext}>{children}</ProjectContext.Provider>
}
