import { FunctionComponent, MouseEvent, RefObject, useContext, useRef, useState } from 'react'
import { IconButton } from '../Button'
import { IconName } from '@components/base/Icon'
import { Popover, PopoverEvent } from '@components/base/Popover'
import { IMenuItemProps, MenuItem } from './MenuItem.tsx'
import classes from './Menu.module.scss'
import { AppSettingsContext } from '@lib/app/settings'

export interface IMenuProps {
    items?: IMenuItemProps[]
    event?: PopoverEvent
    disabled?: boolean
    AnchorComponent?: FunctionComponent<IMenuAnchorProps>
}

export interface IMenuAnchorProps {
    onMenuClick?: () => void
    anchorRef?: RefObject<HTMLButtonElement> | RefObject<HTMLDivElement>
    disabled?: boolean
}

export const Menu: FunctionComponent<IMenuProps> = (props) => {
    const { items = [], event, disabled, AnchorComponent } = props
    const [showMenu, setShowMenu] = useState(false)
    const [hadOutsideClick, setHadOutsideClick] = useState(false)
    const anchorRef = useRef<HTMLButtonElement | null>(null)
    const isDark = useContext(AppSettingsContext).data.theme === 'dark'

    function onMenuClick(event?: MouseEvent<HTMLButtonElement>): void {
        if (event?.target) {
            if (hadOutsideClick) {
                // Do nothing because outside click handler already hid the menu.
            } else {
                setShowMenu(!showMenu)
            }
        } else {
            /**
             * NOTE: If we are here, the function is being called from the outside click
             * handler of the popover component.
             */
            setShowMenu(false)
            setHadOutsideClick(true)

            /**
             * NOTE: We are setting a timeout here to mitigate the fact that this function gets called twice
             * when closing a menu by clicking the button (first from the outside click handler then from the icon
             * button on click handler).
             */
            setTimeout(() => {
                setHadOutsideClick(false)
            }, 200)
        }
    }

    return (
        <div
            className={`w-full flex flex-row items-center justify-end ${classes.menu} ${isDark ? classes.menuDark : ''}`}
        >
            {AnchorComponent ? (
                <AnchorComponent onMenuClick={onMenuClick} anchorRef={anchorRef} disabled={disabled} />
            ) : (
                <IconButton icon={IconName.Actions} onClick={onMenuClick} buttonRef={anchorRef} disabled={disabled} />
            )}
            {!disabled && showMenu && anchorRef && (
                <Popover
                    anchor={anchorRef.current as HTMLButtonElement}
                    event={event ?? 'click'}
                    onOutsideClick={onMenuClick}
                >
                    {items.map((item) => (
                        <MenuItem {...item} />
                    ))}
                </Popover>
            )}
        </div>
    )
}
