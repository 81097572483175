import { FunctionComponent } from 'react'
import type { InputProps } from './input.ts'
import classes from './Input.module.scss'

/**
 * Displays a text-based input component to the page.
 *
 * @param props
 */
export const TextInput: FunctionComponent<InputProps> = (props) => {
    const { id, value, label, error, maxLength, required, disabled, autofocus, readonly = false } = props
    return (
        <div className='flex flex-col space-y-2 transparent'>
            {label && (
                <label className={`text-text-primary dark:text-text-primary-dark ${classes.label}`} htmlFor={id}>
                    {label}
                </label>
            )}
            <input
                className={`bg-surface-0 dark:bg-surface-0-dark text-text-primary dark:text-text-primary-dark caret-text-brand-primary ${classes.input} ${error ? classes.errorInput : ''}`}
                id={id}
                value={value}
                type='text'
                maxLength={maxLength ?? 4096}
                required={required ?? false}
                disabled={disabled ?? false}
                autoFocus={autofocus ?? false}
                readOnly={readonly}
            />
            {error && <p className={classes.errorText}>{error}</p>}
        </div>
    )
}
