import { IContext } from './context.interface'

export function createEmptyContext<T>(data: T | null): IContext<T> {
    return {
        /**
         * NOTE: We accept `null` values so that objects without sensible
         * defaults (e.g. user, projects) can be initialized to falsy values,
         * making it easier to write logic where the context is used.
         */
        data: data as T,
        setData: (_) => {},
    }
}
