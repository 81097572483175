import { CookiesProvider } from 'react-cookie'
import { useAuth0 } from '@auth0/auth0-react'
import { AppProvider } from '@components/app'
import { Loading } from '@components/base'
import { RouterProvider } from '@pages/Router'

const App = () => {
    const { isLoading, error } = useAuth0()

    if (isLoading) {
        return <Loading />
    }

    if (error) {
        console.error('Error ', error)
    }

    return (
        <CookiesProvider defaultSetOptions={{ path: '/' }}>
            <AppProvider>
                <RouterProvider />
            </AppProvider>
        </CookiesProvider>
    )
}

export default App
