import { FunctionComponent } from 'react'
import { IconName } from '@components/base/Icon'
import { Text } from '@components/base/Text'

export interface IMenuItemProps {
    title: string
    onClick: () => void
    variant?: MenuItemVariant
    icon?: IconName
}

const menuItemVariants = ['primary', 'danger'] as const
export type MenuItemVariant = (typeof menuItemVariants)[number]

const MENU_ITEM_VARIANT_BACKGROUND_COLOR_MAP: Record<MenuItemVariant, string> = {
    primary:
        'bg-[var(--surface-0)] dark:bg-[var(--surface-0-dark)] hover:bg-[#d9e1e8]/[.3] dark:hover:bg-[#d9e1e8]/[.05]',
    danger: 'bg-[var(--surface-0)] dark:bg-[var(--surface-0-dark)] hover:bg-[#ff3232]/[.1] dark:hover:bg-[#ff3232]/[.1]',
}

const MENU_ITEM_VARIANT_TEXT_COLOR_MAP: Record<MenuItemVariant, string> = {
    primary: 'text-primary',
    danger: 'text-danger',
}

export const MenuItem: FunctionComponent<IMenuItemProps> = (props) => {
    const { title, onClick, variant } = props
    return (
        <div
            onClick={onClick}
            className={`w-full px-6 py-2 cursor-pointer whitespace-nowrap transition-colors duration-300 ${MENU_ITEM_VARIANT_BACKGROUND_COLOR_MAP[variant ?? 'primary']}`}
        >
            <Text type='body1' justification='center' color={MENU_ITEM_VARIANT_TEXT_COLOR_MAP[variant ?? 'primary']}>
                {title}
            </Text>
        </div>
    )
}
