import type { IProjectDetails } from '@lib/app/project'
import type { IReadingDashboardContext, ISensorData } from './sensor.types.ts'

export function getSensorDataFromDashboardContext(context: IReadingDashboardContext, unit: string): ISensorData {
    return {
        timestamp: new Date(context.timestamp),
        value: context.value,
        address: context.address,
        annotations: Array.from(Object.values(context.annotations)),
        score: parseInt((context.score * 100).toFixed()),
        unit,
    }
}

export function getNumberOfSensorsOnline(project: IProjectDetails): number {
    let online = 0
    Object.entries(project.sensors.sensors).forEach(([_, sensor]) => {
        if (
            Object.entries(sensor.readings).some(([_, reading]) => {
                // Determine the offset based on the equipment group
                const offset = sensor.equipment.group === 'Manual' ? 60 * 24 : 60

                try {
                    // Parse the timestamp and calculate the time difference
                    const timestamp = new Date(reading.timestamp)
                    const now = new Date()
                    const timeDifferenceInMinutes = now.getMinutes() - timestamp.getMinutes() // Convert milliseconds to minutes

                    // Check if the time difference is within the offset
                    if (timeDifferenceInMinutes < offset) {
                        return true
                    }
                } catch (e) {
                    // Handle parsing errors (if timestamp is invalid)
                    return false
                }
            })
        ) {
            online++
        }
    })

    return online
}
