import { useContext, useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import { ISensorContextData } from '@lib/app/sensor'
import { CargoDataSource, FeedstockDataSource, FlowmeterDataSource } from './Sources'
import { ProjectContext } from '@lib/app/project'

const CARGO: string = 'cargo'
const FLOW: string = 'flow'
const FEED: string = 'feed'
export type SensorTypes = typeof CARGO | typeof FLOW | typeof FEED

function sensorToTemplate(sensor: string): SensorTypes {
    sensor = sensor.toLowerCase()
    if (sensor.includes('cargo') || sensor.includes('grape')) {
        return CARGO
    } else if (sensor.includes('flow')) {
        return FLOW
    } else if (sensor.includes('feed')) {
        return FEED
    } else {
        return CARGO
    }
}

export const ProjectDataSourcePage = () => {
    const { sensorsSlug } = useParams()
    const { details } = useContext(ProjectContext).data
    const [sensor, setSensor] = useState<ISensorContextData | null>(null)

    useEffect(() => {
        if (details) {
            const _sensor = Object.values(details.sensors.sensors).find((sensor) => sensor.id === sensorsSlug)
            _sensor && setSensor(_sensor)
        }
    }, [details])

    if (sensor) {
        const type = sensorToTemplate(sensorsSlug!)
        switch (type) {
            case CARGO:
                return <CargoDataSource sensor={sensor} />
            case FLOW:
                return <FlowmeterDataSource sensor={sensor} />
            case FEED:
                return <FeedstockDataSource sensor={sensor} />
        }
    } else {
        return <></>
    }
}
