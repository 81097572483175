import * as changeCase from 'change-case'

export function strToBool(fallback: boolean, value?: string): boolean {
    switch (value?.toLocaleLowerCase()) {
        case 'true':
            return true
        case 'false':
            return false
        default:
            return fallback
    }
}

export function strToCamelCase(str: string): string {
    // Using replace method with regEx
    return str
        .replace(/(?:^\w|[A-Z]|\b\w)/g, function (word, index) {
            return index == 0 ? word.toLowerCase() : word.toUpperCase()
        })
        .replace(/\s+/g, '')
}

export function strFromPascalToCamelCase(input: string): string {
    if (input) {
        return changeCase.camelCase(input)
    } else {
        return input
    }
}

export function truncateString(str: string, maxCharacters: number) {
    return str.length > maxCharacters ? str.substr(0, maxCharacters - 1) + '…' : str
}
