import { FunctionComponent, ReactNode, useContext } from 'react'
import classes from './Pane.module.scss'
import { AppSettingsContext } from '@lib/app/settings'

interface IPane {
    children: ReactNode
    padding?: PanePadding
    unClipped?: boolean
}

const panePaddings = ['square', 'top'] as const
type PanePadding = (typeof panePaddings)[number]

const PANE_PADDING_MAPPINGS: Record<PanePadding, string> = {
    square: 'p-6',
    top: 'px-6 pt-8 pb-6',
}

export const Pane: FunctionComponent<IPane> = (props) => {
    const { children, padding, unClipped } = props
    const isDark = useContext(AppSettingsContext).data.theme === 'dark'
    return (
        <div className={`${PANE_PADDING_MAPPINGS[padding ?? 'square']} ${classes.pane} ${isDark && classes['pane-dark']} && ${!unClipped && 'clipped'}`}>
            {children}
        </div>
    )
}
