// AuthService.ts
import { BaseApiService } from '@lib/core/api'

export class AuthApiService extends BaseApiService {
    constructor(baseURL: string) {
        super(baseURL)
    }

    async status(): Promise<any> {
        return this.get('api/auth/session-status')
    }

    async login(username: string, token: string, refresh_token: string) {
        return this.post('api/auth/login', { username, token, refresh_token })
    }

    async logout() {
        return this.get('api/auth/logout')
    }

    async createPaymentIntent(
        customerId: string,
        productId: string,
        feeId: string,
        returnUrl: string,
        projectId: string
    ) {
        return this.post('api/auth/create-payment-intent', { customerId, productId, feeId, returnUrl, projectId })
    }
}
