import { NavigateFunction } from 'react-router-dom'

export class NavigatorService {
    public static navigator: NavigateFunction

    // Gets set on App errorhandler init.
    public static setNavigator(navigator: NavigateFunction) {
        NavigatorService.navigator = navigator
    }
}
